import React from "react";
import SectionApp from "./SectionApp";
import { socials } from "../constantsLanding";

const FooterApp = () => {
  return (
    <SectionApp crosses className="!px-0 !py-10">
      <div className="container flex sm:justify-between justify-center items-center gap-10 max-sm:flex-col">
        
        {/* Wrap links in a column layout */}
        <div className="flex flex-col items-center gap-2 sm:gap-10">
          <a
            href="/termsofservice"
            className="text-n-4 hover:underline"
          >
            Terms of Service
          </a>
          <a
            href="/privacypolicy"
            className="text-n-4 hover:underline"
          >
            Privacy policy
          </a>
        </div>

        <p className="caption text-n-4 lg:block">
          © {new Date().getFullYear()}. All rights reserved.
        </p>

        <ul className="flex gap-5 flex-wrap">
          {socials.map((item) => (
            <a
              key={item.id}
              href={item.url}
              target="_blank"
              className="flex items-center justify-center w-10 h-10 bg-n-7 rounded-full transition-colors hover:bg-n-6"
            >
              <img src={item.iconUrl} width={16} height={16} alt={item.title} />
            </a>
          ))}
        </ul>
      </div>
    </SectionApp>
  );
};

export default FooterApp;
