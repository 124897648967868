import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Alert, Spinner, Button } from 'react-bootstrap';
import {  useParams, useNavigate } from 'react-router-dom';
import './PaymentStatus.css';

const PaymentStatus = () => {
  const [status, setStatus] = useState('pending');
  const [message, setMessage] = useState('Payment is pending...');
  const { subId } = useParams();
  const socketRef = useRef(null);

  useEffect(() => {
    const socket = new WebSocket('ws://localhost:7046/api/paymentstatusws');
    socketRef.current = socket;

    socket.onopen = () => {
      setMessage('Oczekiwanie na płatność... ');
      socket.send(subId);
    };

    socket.onmessage = (event) => {
      const data = event.data;
      setStatus(data);
    };

    socket.onerror = (error) => {
      setStatus('error');
      setMessage('Connection error. Please try again later.');
    };

    return () => {
      socket.close();
    };
  }, []);

  const navigate = useNavigate();
  const handleHomeClick = () => {
    navigate('/home');
  }

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center vh-100 text-center payment-status-container">
      <Row>
        <Col>

        </Col>
      </Row>
      {status === 'pending' && (
        <Row>
          <Col>
            <Alert variant="info" className="text-center status-alert">
              {message}
              <Spinner animation="border" size="sm" className="ml-2 status-spinner" />
            </Alert>
          </Col>
        </Row>
      )}
      {status === 'active' && (
        <Row>
          <Col>
            <h1 className="text-success status-success">Płatność powiodła się!</h1>
            <p className="lead status-message">
              Dziękujemy za płatność. Twoja płatność została pomyślnie przetworzona.
            </p>
            <Button variant="primary" style={{ fontSize: '1.1rem' }} onClick={handleHomeClick}>
              Wróć do strony głównej
            </Button>
          </Col>
        </Row>
      )}
      {status === 'cancelled' && (
        <Row>
          <Col>
            <h1 className="display-4 text-danger status-failed">
              Płatność nie powiodła się.
            </h1>
            <p className="lead status-message">
              Wystąpił problem z przetwarzaniem Twojej płatności.
            </p>
            <Button variant="primary" style={{ fontSize: '1.2rem' }} href="/subscription/premium">
              Ponów płatność
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default PaymentStatus;
