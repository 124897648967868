import React, { useState, useEffect, useRef } from 'react';
import { useKeycloak } from "@react-keycloak/web";
import axios from '../components/AxiosConfig';

const useFetchWordsBookHistory = (pageNumber, bookId) => {
  
  const [fetchError, setFetchError] = useState(null);
  const [wordsHistoryData, setWordsHistoryData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {

        if(pageNumber.pageIndex === null){
          return;
        }
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/HistoryBook/words/${bookId}/${pageNumber.pageIndex}/1000/${pageNumber.next}`);
        const data = response.data;
        setWordsHistoryData(data);

      } catch (error) {
        setFetchError(error);
      }
    };

    fetchData();
  }, [pageNumber.pageIndex, pageNumber.next]);

  return { wordsHistoryData };
};

export default useFetchWordsBookHistory;