// PrivateRoute.js
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { KeycloakContext } from './components/KeycloakProvider.js';

const PrivateRoute = ({ element: Component, ...rest }) => {
  const { keycloak, authenticated } = useContext(KeycloakContext);
  const location = useLocation();

  useEffect(() => {
    if (authenticated === false && keycloak) {
      // User is not authenticated, initiate Keycloak login
      keycloak.login({ redirectUri: window.location.origin + location.pathname });
    }
  }, [authenticated, keycloak, location]);

  if (authenticated === null) {
    // Authentication status is not yet determined
    return null; // Or a loading indicator
  }

  if (authenticated === true) {
    // User is authenticated, render the component
    return <Component {...rest} />;
  }

  // While the login process is happening, you can render a loading indicator or null
  return null;
};

export default PrivateRoute;
