import React, { useState, useEffect } from 'react';
import './BooksGrid.css';
import axios from '../AxiosConfig';
import Box from '@material-ui/core/Box';
import defaultImage from '../../images/book-default-min.png';
import BarLoader from 'react-spinners/BarLoader';
import { Link } from 'react-router-dom';
import Flag from 'react-flagkit';
import A1Icon from '../../icons/A1Icon';
import B1Icon from '../../icons/B1Icon';
import C1Icon from '../../icons/C1Icon';
import { bookLanguages } from '../../constants/global.js';
import { useTranslation } from 'react-i18next';

const PopularBooksGrid = ({ category, language, drawerLanguage }) => {
  const { t } = useTranslation();
  const [books, setBooks] = useState(null);
  const [page, setPage] = useState({ skip: 0, limit: 12 });
  const [loading, setLoading] = useState(false);
  const [allElements, setAllElements] = useState(null);
  const [loadedImages, setLoadedImages] = useState([]);
  const [oldCategory, setOldCategory] = useState([]);
  const [oldLanguage, setoldLanguage] = useState([]);
  const [oldDrawerLanguage, setDrawerLanguage] = useState([]);
  const [invoke, setInvoke] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(category?.category?.Name === '' || category?.category?.Name === undefined){
          return;
        }
        setLoading(true);
        var response;
         if (oldCategory?.Name !== category.category.Name || language?.name !== oldLanguage?.name || oldDrawerLanguage !== drawerLanguage ) {
          setBooks(null);
          setPage({
            skip: 0,
            limit: 12
          });
           response = await axios.get(`${process.env.REACT_APP_API_URL}/api/fulltextsearch/category/popular?category=${category.category.Native}&skip=0&limit=12`);
         }
         else {
          response = await axios.get(`${process.env.REACT_APP_API_URL}/api/fulltextsearch/category/popular?category=${category.category.Native}&skip=${page.skip}&limit=${page.limit}`);
         }

        const data = response.data;
        setAllElements(data.totalCount);

        const newLoadedImages = [];
        data.books.forEach((book) => {
          newLoadedImages[book.id] = false; // Initialize all as not loaded
        });
        setLoadedImages(newLoadedImages);

        setBooks((prevItems) => {
          if (prevItems === null || page.skip === 0) {
            return [...data.books];
          } else {
            return [...prevItems, ...data.books];
          }
        });
        setLoading(false);
        setOldCategory(category.category);
        setoldLanguage(language);
        setDrawerLanguage(drawerLanguage);
      } catch (error) {
        console.error('Failed to fetch books:', error); // Error handling
        setLoading(false); // Ensure loading is set to false on error
      }
    };

    if (page.skip === 0) {
      setBooks(null);
      setLoadedImages([]);
    }

    fetchData();
  }, [category.category?.Native, category.language, invoke]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 100 >=
          document.documentElement.offsetHeight &&
        !loading &&
        page.skip + 12 <= allElements
      ) {
        loadMoreBooks();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, page, allElements]);

  const loadMoreBooks = () => {
    setPage((prevPage) => ({
      skip: prevPage.skip + 12,
      limit: 12,
    }));
    setInvoke(!invoke);
  };

  const handleImageLoaded = (bookId) => {
    setLoadedImages((prev) => ({ ...prev, [bookId]: true }));
  };

  return (
    <div
      style={{
        background: '#F3F4F6',
        borderRadius: '30px',
        marginTop: '10px',
        marginBottom: '40px',
        minHeight: '300px',
      }}
    >
      <div className="books-grid-popular">
        {books &&
          books.map((book) => (
            <div
              key={book.id}
              style={{
                display:
                  loadedImages &&
                  (!Object.keys(loadedImages).some((x) => x === book.id) ||
                  Object.values(loadedImages).every((value) => value === true)
                    ? 'block'
                    : 'none'),
              }}
            >
              <Link
                to={`/bookdetails/${book.id}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <div className="book-tile" style={{ position: 'relative' }}>
                  <img
                    src={book.imageUrl || defaultImage}
                    alt={book.title}
                    onLoad={() => handleImageLoaded(book.id)}
                    style={{
                      width: '100%',
                      height: 'auto',
                      display: 'block',
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 3,
                      zIndex: 2,
                    }}
                  >
                    {console.log("book.language", book.language)}
                    <Flag
                      country={bookLanguages.find(x => x.name === book.language)?.flag || 'US'}
                      style={{
                        width: '1.2rem',
                        height: '1.2rem',
                        borderRadius: '5px',
                      }}
                    />
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      top: 15,
                      right: 3,
                      zIndex: 2,
                    }}
                  >
                     {book.languageLevel === 'basic' && <A1Icon/> }
                     {book.languageLevel === 'intermediate' && <B1Icon/> }
                     {book.languageLevel === 'advanced' && <C1Icon/> }
                  </div>
                  <span className="book-name">{book.title}</span>
                  <span className="book-author">
                    {book.authors.length > 0
                      ? book.authors[0].name
                      : t('unknownAuthor')}
                  </span>
                </div>
              </Link>
            </div>
          ))}
      </div>
      {(loading || Object.values(loadedImages).some((value) => value === false)) && (
        <Box className="books-spinner">
          <div style={{ height: '25px', marginTop: '25px' }}>
            <BarLoader size={25} color="#00B2FF" />
          </div>
        </Box>
      )}
    </div>
  );
};

export default PopularBooksGrid;
