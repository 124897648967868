import React, { useState, useEffect, useRef } from 'react';
import { useKeycloak } from "@react-keycloak/web";
import axios from '../components/AxiosConfig';

const useFetchData = (pageNumber, bookId) => {
  const [textArray, setTextArray] = useState([]);
  const [wordsArray, setWordsArray] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [bookContent, setBookContent] = useState("");
  const wordRefs = useRef([]);
  const wordSpaceRefs = useRef([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setTextArray([]);
        setWordsArray([]);
        wordRefs.current = [];
        wordSpaceRefs.current = [];

        if(pageNumber.pageIndex === null){
          return;
        }
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/BookPage/${bookId}/${pageNumber.pageIndex}/1000/${!pageNumber.next}`);//, requestOptions
        const data = response.data;
        //const words = data.content.replace(/\n/g, "").split(/\s+/)
        //const words = data.contentArray.filter(x => x.value !== '\r\n');
        //setWordsArray(pageNumber.next ? words : words.reverse());
          // const dataWithEmptySpace = data.content.replace(/\n/g, "[x]").split(/\[x\]|\s+/);
        setBookContent(data);
        //setTextArray(data.contentArray);
        //wordRefs.current = Array(data.contentArray.length)
                                                 //.fill()
                                                 //.map((_, index) => wordRefs.current[index] || React.createRef());
        //wordSpaceRefs.current = Array(data.contentArray.length)
                                                 //.fill()
                                                 //.map((_, index) => wordSpaceRefs.current[index] || React.createRef());
        
      } catch (error) {
        setFetchError(error);
      }
    };

    fetchData();
  }, [pageNumber.pageIndex, pageNumber.next]);

  return { textArray, wordsArray, fetchError, wordRefs, wordSpaceRefs, bookContent };
};

export default useFetchData;
