// App.js
import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { KeycloakContext, KeycloakProvider } from './components/KeycloakProvider';
import { ErrorProvider } from './components/errors/ErrorContext';
import { DrawerProvider } from './components/homePage/DrawerContext';
import { UserProvider } from './components/userContext/UserContext';
import PrivateRoute from './PrivateRoute';
import ErrorModal from './components/errors/ErrorModal';
import InternetStatusHandler from './components/internetStatus/InternetStatusHandler';
import CookieConsent from './components/cookie/CookieConsent';
import I18nLoader from './components/languageSelector/I18nLoader';
import LandingPage from './componentLanding/LandingPage';
import HomePage from "./components/homePage/HomePage";
import TermsOfService from './componentLanding/termsOfPolicy/TermsOfPolicy';
import PrivacyPolicy from './componentLanding/termsOfPolicy/PrivacyPolicy';
import DashboardApp from './components/dashboard/DashboardApp';
import FlashcardList from './components/flashcards/FlashcardList';
import LearningPage from './components/learningPage/LearningPage';
import EbookReader from './components/ebookReader/EbookReader';
import SearchPage from './components/homePage/SearchPage';
import BookDetails from './components/bookDetails/BookDetails';
import Subscription from './components/subscription/Subscription';
import Premium from './components/premium/Premium';
import SuccessPage from './components/premium/SuccessPage';
import FailedPage from './components/premium/FailedPage';
import PaymentStatus from './components/premium/PaymentStatus';
import Navbar from './components/profile/Navbar';
import Support from './components/profile/Support';
import ManageSubscription from './components/profile/ManageSubscription';
import RemoveAccount from './components/profile/RemoveAccount';
import ChangePassword from './components/profile/ChangePassword';
import ChangeTranslationLanguage from './components/profile/ChangeTranslationLanguage';
import ChangeInterfaceLanguage from './components/profile/ChangeInterfaceLanguage';
import Library from './components/library/Library';
import UploadBook from './components/uploadBook/UploadBook';
import LanguageSelector from './components/languageSelector/LanguageSelector';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import './index.css';

function AppRoutes() {
  const { authenticated } = useContext(KeycloakContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated && window.location.pathname === '/') {
      navigate('/home');
    }
  }, [authenticated, navigate]);

  return (
    <Routes>
      {!authenticated && <Route path="/" element={<LandingPage />} />}
      <Route path="/termsofservice" element={<TermsOfService />} />
      <Route path="/dashboard" element={<PrivateRoute element={DashboardApp} />} />
      <Route path="/flashcards/:bookId" element={<PrivateRoute element={FlashcardList} />} />
      <Route path="/learning" element={<PrivateRoute element={LearningPage} />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/home" element={<PrivateRoute element={HomePage} />} />
      <Route path="/subscription" element={<PrivateRoute element={Subscription} />} />
      <Route path="/uploadBook" element={<PrivateRoute element={UploadBook} />} />
      <Route path="/subscription/premium" element={<PrivateRoute element={Premium} />} />
      <Route path="/subscription/success" element={<PrivateRoute element={SuccessPage} />} />
      <Route path="/subscription/status/:subId" element={<PrivateRoute element={PaymentStatus} />} />
      <Route path="/subscription/failed" element={<PrivateRoute element={FailedPage} />} />
      <Route path="/reader/:bookId" element={<PrivateRoute element={EbookReader} />} />
      <Route path="/bookdetails/:bookId" element={<PrivateRoute element={BookDetails} />} />
      <Route path="/search/:value" element={<PrivateRoute element={SearchPage} />} />
      <Route path="/support" element={<PrivateRoute element={Support} />} />
      <Route path="/manage-subscription" element={<PrivateRoute element={ManageSubscription} />} />
      <Route path="/change-password" element={<PrivateRoute element={ChangePassword} />} />
      <Route path="/change-translation-language" element={<PrivateRoute element={ChangeTranslationLanguage} />} />
      <Route path="/change-interface-language" element={<PrivateRoute element={ChangeInterfaceLanguage} />} />
      <Route path="/remove-account" element={<PrivateRoute element={RemoveAccount} />} />
      <Route path="/profile" element={<PrivateRoute element={Navbar} />} />
      <Route path="/library" element={<PrivateRoute element={Library} />} />
      <Route path="/initialLanguages" element={<PrivateRoute element={LanguageSelector} />} />
    </Routes>
  );
}

function App() {
  return (
    <BrowserRouter>
      <ErrorProvider>
        <KeycloakProvider>
          <InternetStatusHandler />
          <DrawerProvider>
            <UserProvider>
              <I18nLoader />
              <AppRoutes />
            </UserProvider>
          </DrawerProvider>
          <CookieConsent />
          <ErrorModal />
        </KeycloakProvider>
      </ErrorProvider>
    </BrowserRouter>
  );
}

export default App;
