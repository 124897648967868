import React, { useState, useEffect, useRef } from 'react';
import PopularBooksGrid from './PopularBooksGrid';
import RecentBook from '../../images/recent_books.PNG';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { ReactSearchAutocomplete } from 'search-elastic';
import defaultImage from '../../images/book-default-min.png';
import axios from '../../components/AxiosConfig';
import SearchAdvanced from '../../icons/SearchAdvanced';
import SearchBasic from '../../icons/SearchBasic';
import SearchIntermediate from '../../icons/SearchIntermediate';
import { Box, Container, Typography, List, ListItem, ListItemText, useMediaQuery, useTheme, Button, CircularProgress, MenuItem } from '@mui/material';
import { useDrawer } from './DrawerContext';
import Flag from 'react-world-flags';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TextField, Autocomplete, Popper, Paper  } from '@mui/material';
import { bookLanguages } from '../../constants/global.js';
import { useTranslation } from 'react-i18next';
import PopularIcon from '../../icons/PopularIcon.js';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import BookIcon from '../../icons/BookIcon.js';
import './BookShelf.css'
const BookShelf = ({drawerLanguage, userData}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  let { categoryName } = location.state || {};
  const [searchResults, setSearchResults] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery('(max-width:900px)');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [isWide, setIsWide] = useState(window.innerWidth >= 1290);
  const { handleOpenLeftDrawer } = useDrawer();
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (drawerLanguage) {
      const categoriesFile = require(`../../categories/${drawerLanguage}.json`);
      const categoriesChange = categoriesFile[userData.interfaceLanguage] || categoriesFile["English"]
      setSelectedCategory({category: categoriesChange[0], language: drawerLanguage});
      setCategories(categoriesChange);
    }
  }, [drawerLanguage]);

  const handleButtonClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleLanguageChange = async(event, newValue) => {

    if(newValue === null)
      return;

    console.log("handleLanguageChange");
    const lang = newValue.name;
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/libraryLanguage`, { libraryLanguage: lang }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        fetchCategoriesData(newValue.name, userData.interfaceLanguage);
        setSelectedLanguage(newValue);
        const categoriesFile = require(`../../categories/${newValue.name}.json`);
        const categoriesChange = categoriesFile[userData.interfaceLanguage] || categoriesFile["English"]

        localStorage.setItem('selectedCategory', JSON.stringify(categoriesChange[0]));
        setSelectedCategory({category: categoriesChange[0], language: newValue});
      } else {
        console.error('Failed to save library language');
      }
    } catch (error) {
      console.error('Error saving library language:', error);
    }
    setAnchorEl(null); // Close popper when a language is selected
  };

  const ListIcon = ({ color }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="6" r="2" fill={color} />
      <rect x="8" y="5" width="12" height="2" fill={color} />
      <circle cx="4" cy="12" r="2" fill={color} />
      <rect x="8" y="11" width="12" height="2" fill={color} />
      <circle cx="4" cy="18" r="2" fill={color} />
      <rect x="8" y="17" width="12" height="2" fill={color} />
    </svg>
  );
  
  const fetchCategoriesData = async (libraryLanguage, interfaceLanguage) => {
    try {
      const categoriesFile = require(`../../categories/${libraryLanguage}.json`);
      const categories = categoriesFile[interfaceLanguage] || categoriesFile["English"]
      setCategories(categories);
      const savedCategory = JSON.parse(localStorage.getItem('selectedCategory'));
      if (!categoryName && !savedCategory) {
        categoryName = categories[0];
        localStorage.setItem('selectedCategory', JSON.stringify(categoryName));
      }
      setSelectedCategory({category: categoryName || savedCategory || categories[0], language: selectedLanguage});
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if(userData){
      console.log("categoryName", categoryName)
      setSelectedCategory({category: categoryName, language: selectedLanguage});
        fetchCategoriesData(userData.libraryLanguage, userData.interfaceLanguage);
      setSelectedLanguage(bookLanguages.find(language => language.name === userData.libraryLanguage));
    }
  }, [userData]);

  useEffect(() => {
      if(categoryName === undefined){
        return;
      }
      fetchCategoriesData(selectedLanguage);
      setSelectedCategory({category: categoryName, language: selectedLanguage});
  }, [categoryName]);

  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth >= 1020);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory({category: category, language: selectedLanguage});
    localStorage.setItem('selectedCategory', JSON.stringify(category));
    navigate('/home', { state: { categoryName: category } });
  };

  const handleOnSearch = async (query) => {
    try {
      if (query === '') {
        setSearchResults([]);
        return;
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/fulltextsearch/book?search=${query}&skip=0&limit=10`);
      const formattedResults = response.data.map(book => ({
        author: book.authors.length > 0 ? book.authors[0].name : t('unknownAuthor'),
        name: book.title,
        genreInner: book.genreInner,
        languageLevel: book.languageLevel,
        url: book.url,
        genreMain: book.genreMain,
        id: book.id,
        imageUrl: book.imageUrl,
        language: book.language
      }));
      setSearchResults(formattedResults);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handleOnSelect = (item) => {
    navigate(`/bookdetails/${item.id}`);
  };

  const formatResult = (item) => {
    return (
      <Link to={`/bookdetails/${item.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img style={{ height: '70px', width: '70px', marginRight: '1rem' }} src={item.imageUrl || defaultImage} alt="book" />
          <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 'calc(100% - 80px)' }}>
            <span style={{ fontSize: '18px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</span>
            <span style={{ color: '#96a3ac', fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.author}</span>
            <span style={{ display: 'flex', fontSize: '12px' }}>{t('languageLevel')}:
              {item.languageLevel === 'basic' && <span style={{ display: 'inline-flex' }}><SearchBasic /></span>}
              {item.languageLevel === 'intermediate' && <span style={{ display: 'inline-flex' }}><SearchIntermediate /></span>}
              {item.languageLevel === 'advanced' && <span style={{ display: 'inline-flex' }}><SearchAdvanced /></span>}
              <Flag code={bookLanguages.find(x => x.name === item.language)?.flag || 'US'} style={{ width: '20px'}} />
            </span>
            <span style={{ fontSize: '11px' }}>Genre:
              <span style={{ background: '#7853ea', color: 'white', marginLeft: '5px', borderRadius: '5px' }}>&nbsp;&nbsp;{item.genreMain}&nbsp;&nbsp;</span>
              <span style={{ background: '#7853ea', color: 'white', marginLeft: '5px', borderRadius: '5px' }}>&nbsp;&nbsp;{item.genreInner}&nbsp;&nbsp;</span>
            </span>
          </div>
        </div>
      </Link>
    );
  };

  const visibleCategories = showAllCategories ? categories.slice(4) : categories.slice(4, 15);

  return (
    <Container>
      <Box className="searchbx" mt={2} mb={2} style={{ zIndex: 1, maxWidth: '500px', margin: '1.3rem auto'}} >
        <ReactSearchAutocomplete
          items={searchResults}
          onSearch={handleOnSearch}
          onSelect={handleOnSelect}
          onClear={() => setSearchResults([])}
          styling={{ zIndex: 10 }}
          formatResult={formatResult}
          showIcon={true}
          fuseOptions={{ threshold: 1 }}
          placeholder={t('searchForABookOrAuthor')}
          maxResults={30}
        />
      </Box>
      {isMobile && (
        <Button 
          variant="outlined" 
          style={{ 
            margin: '10px auto',
            marginBottom: '20px',
            display: 'flex', 
            width: '98%', 
            color: '#00B2FF', 
            borderColor: '#00B2FF', 
            fontSize: '1.0em', 
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 16px',
            borderRadius: '10px'
          }} 
          onClick={() => handleOpenLeftDrawer(t, true)}
        >
          <ListIcon color="#00B2FF"/>
          <span style={{ marginLeft: 'auto' }}></span>
          <span style={{ marginRight: 'auto', fontFamily: '"Source Code Pro", monospace', fontWeight: 700}}>{t('showCategories')}</span>
        </Button>
      )}
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
        {isDesktop && (
          <Box
          width={{ md: '250px' }}
          mr={{ md: 4 }}
          mb={{ xs: 3, md: 0 }}
          mt={{ xs: 3, md: -1 }}
          style={{
            marginRight: '30px',
            maxHeight: '800px',
            background: '#F3F4F6',
            borderRadius: '15px',
            overflowX: 'auto', // Enable horizontal scroll
            whiteSpace: 'nowrap', // Prevent wrapping of child elements, enabling horizontal scroll
            scrollbarWidth: 'thin', // For Firefox: thin scrollbar
            WebkitOverflowScrolling: 'touch', // For smooth scrolling on mobile devices
          }}
        >
            <Typography 
              variant="h6" 
              fontWeight="bold" 
              mb={2}
              width={200} 
              style={{ whiteSpace: isWide ? 'nowrap' : 'normal' }}
            >
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
  <Button
    aria-haspopup="true"
    onClick={handleButtonClick}
    sx={{
      color: 'black',
      fontWeight: 'bold',
      fontSize: '1.0rem',
      textTransform: 'none',
      marginRight: '15px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '@media (min-width: 600px)': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 'auto',
      },
    }}
  >
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexDirection: 'row', ml: '0px', fontSize: '1.0rem', border: '1px solid grey',borderRadius:'10px',padding: '5px', marginLeft: '5px', fontSize: '16px', fontFamily: '"Source Code Pro", monospace', fontWeight: 700}}>
      {t('booksLanguage')}
      <Box sx={{ ml: 1, display: 'flex', alignItems: 'center', '@media (min-width: 600px)': { ml: 1 } }}>
        <Flag code={selectedLanguage?.flag} style={{ width: '20px', marginLeft: '8px' }} />
        <ArrowDropDownIcon />
      </Box>
    </Box>
  </Button>
  <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start" style={{ zIndex: 10, width: 175}}>
    <Paper ref={autocompleteRef} sx={{ width: 175, bgcolor: 'white', marginLeft: '10px' }}>
      <Autocomplete
        options={bookLanguages}
        getOptionLabel={(option) => option.name}
        value={selectedLanguage}
        onChange={handleLanguageChange}
        inputValue={searchTerm}
        onInputChange={(event, newInputValue) => setSearchTerm(newInputValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            placeholder="Search language"
            sx={{
              '& .MuiInputBase-root': {
                fontFamily: '"Source Code Pro", monospace',
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <Box component="li" {...props} sx={{fontFamily: '"Source Code Pro", monospace'}}>
            <Flag code={option.flag} style={{ width: '20px', marginRight: '8px' }} />

            {option.name}
          </Box>
        )}
        onClose={() => {
          setAnchorEl(null);
        }}
        open={Boolean(anchorEl)}
        sx={{ width: 175 }}
      />
    </Paper>
  </Popper>
</Box>
<ListItemText
  primary={t('popularCategories')}
  primaryTypographyProps={{
    component: Link,
    textAlign: "left",
    sx: { textDecoration: 'none', color: '#00B2FF', fontSize: '1.1rem', marginLeft: '14px',marginBottom: '10px', fontFamily: '"Source Code Pro", monospace', fontWeight: 700 }
  }}
/>

<MenuItem
  key="{index}"
  onClick={() => handleCategoryClick(categories[0])}
  style={{
    borderLeft: selectedCategory?.category?.Name === categories[0]?.Name ? '4px solid #00B2FF' : 'none',
    backgroundColor: selectedCategory?.category?.Name === categories[0]?.Name ? 'rgba(151, 185, 219, 0.2)' : 'transparent',
    '&:hover': {
      bgcolor: 'rgba(151, 185, 219, 0.2)',
    },
    height: '40px'
  }}
>
  <div style={{marginLeft: '-7px'}}>
    <PopularIcon/>
  </div>
  <ListItemText
    primary={categories[0]?.Name}
    primaryTypographyProps={{
      textAlign: 'left',
      sx: { marginLeft: '5px', fontSize: '18px', fontFamily: '"Source Code Pro", monospace', fontWeight: 600 }
    }}
  />
</MenuItem>

<MenuItem
  key="{index}"
  onClick={() => handleCategoryClick(categories[1])}
  style={{
    borderLeft: selectedCategory?.category?.Name === categories[1]?.Name ? '4px solid #00B2FF' : 'none',
    backgroundColor: selectedCategory?.category?.Name === categories[1]?.Name ? 'rgba(151, 185, 219, 0.2)' : 'transparent',
    '&:hover': {
      bgcolor: 'rgba(151, 185, 219, 0.2)',
    },
    height: '40px'
  }}
>
  <FiberManualRecordIcon style={{ fontSize: '10px', marginRight: '10px' }}/>
  <ListItemText
    primary={
      <>
        <span>{categories[1]?.Name}</span>&nbsp;
        <span style={{color: '#00B2FF'}}>[A1]</span>
      </>
    }
    primaryTypographyProps={{
      textAlign: 'left',
      sx: { fontSize: '18px', fontFamily: '"Source Code Pro", monospace', fontWeight: 600 }
    }}
  />
</MenuItem>

<MenuItem
  key="{index}"
  onClick={() => handleCategoryClick(categories[2])}
  style={{
    borderLeft: selectedCategory?.category?.Name === categories[2]?.Name ? '4px solid #00B2FF' : 'none',
    backgroundColor: selectedCategory?.category?.Name === categories[2]?.Name ? 'rgba(151, 185, 219, 0.2)' : 'transparent',
    '&:hover': {
      bgcolor: 'rgba(151, 185, 219, 0.2)',
    },
    height: '40px'
  }}
>
  <FiberManualRecordIcon style={{ fontSize: '10px', marginRight: '10px' }}/>
  <ListItemText
    primary={
      <>
        <span>{categories[2]?.Name}</span>&nbsp;
        <span style={{color: '#00B2FF'}}>[B1]</span>
      </>
    }
    primaryTypographyProps={{
      textAlign: 'left',
      sx: { fontSize: '18px', fontFamily: '"Source Code Pro", monospace', fontWeight: 600 }
    }}
  />
</MenuItem>

<MenuItem
  key="{index}"
  onClick={() => handleCategoryClick(categories[3])}
  style={{
    borderLeft: selectedCategory?.category?.Name === categories[3]?.Name ? '4px solid #00B2FF' : 'none',
    backgroundColor: selectedCategory?.category?.Name === categories[3]?.Name ? 'rgba(151, 185, 219, 0.2)' : 'transparent',
    '&:hover': {
      bgcolor: 'rgba(151, 185, 219, 0.2)',
    },
    height: '40px'
  }}
>
  <FiberManualRecordIcon style={{ fontSize: '10px', marginRight: '10px' }}/>
  <ListItemText
    primary={
      <>
        <span>{categories[3]?.Name}</span>&nbsp;
        <span style={{color: '#00B2FF'}}>[C1]</span>
      </>
    }
    primaryTypographyProps={{
      textAlign: 'left',
      sx: { fontSize: '18px', fontFamily: '"Source Code Pro", monospace', fontWeight: 600 }
    }}
  />
</MenuItem>

<ListItemText
  primary={t('allCategories')}
  primaryTypographyProps={{
    component: Link,
    textAlign: "left",
    sx: { textDecoration: 'none', color: '#00B2FF', fontSize: '1.1rem', marginLeft: '14px', fontFamily: '"Source Code Pro", monospace', fontWeight: 700 }
  }}
/>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress sx={{ color: '#00B2FF' }} />
              </Box>
            ) : (
              <>
                <List>
                  {visibleCategories.map((category, index) => (
                    <ListItem
                      key={index}
                      button
                      onClick={() => handleCategoryClick(category)}
                      selected={selectedCategory?.category?.Name === category.Name}
                      sx={{
                        borderLeft: selectedCategory?.category?.Name === category.Name ? '4px solid #00B2FF' : 'none',
                        bgcolor: selectedCategory?.category?.Name === category.Name ? 'rgba(151, 185, 219, 0.08)' : 'transparent',
                        '&:hover': {
                          bgcolor: 'rgba(151, 185, 219, 0.08)',
                        },
                        height: '40px'
                      }}
                    >
                       <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{
                          display: 'inline-block',
                          width: '5px',
                          height: '5px',
                          backgroundColor: '#00B2FF',
                          marginRight: '10px'
                        }}></span>
                        <ListItemText
                          primary={category.Name}
                          primaryTypographyProps={{
                            style: {
                              fontFamily: '"Source Code Pro", monospace', fontWeight: 500,
                              fontSize: '1.1rem'
                            }
                          }}
                        />
                      </div>
                    </ListItem>
                  ))}
                </List>
                {categories.length > 15 && (
                  <Button onClick={() => setShowAllCategories(!showAllCategories)} style={{fontFamily: '"Source Code Pro", monospace'}}>
                    {showAllCategories ? 'Show Less' : 'Show More'}
                  </Button>
                )}
              </>
            )}
          </Box>
        )}
        <Box flex={1} style={{
            background: '#F3F4F6',
            borderRadius: '30px',
            boxShadow: '0 0 0 13px #F3F4F6', // Creates a background outside the element
            margin: '0px',
            marginTop: 0 // Adjusts the position of the element to account for the extra background
          }}>
          <Box display="flex" alignItems="center" mb={3}>
            <div style={{
              marginBottom: '-0.9rem',
              marginRight: '5px'
            }}>
            <BookIcon/>
            </div>
            <Box display="flex" alignItems="center">
              <Typography variant="h5" fontWeight="bold" component="span" style={{marginBottom: '-1rem', fontFamily: '"Source Code Pro", monospace', fontWeight: 700}}>
                {selectedCategory?.category?.Name && selectedCategory?.category?.Name !== categories[0]?.Name ? `${selectedCategory?.category?.Name?.toUpperCase()}` : categories[0]?.Name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="span" style={{ marginLeft: '10px', marginBottom: '-1rem',fontFamily: '"Source Code Pro", monospace'}}>
                              [{selectedCategory?.category?.Name ? `+${categories.find(category => category.Name === selectedCategory.category.Name)?.Count}` : `${categories[0]?.Count}`}]
              </Typography>
            </Box>
          </Box>

          <PopularBooksGrid category={selectedCategory ?? {category: categories[0], language: selectedLanguage}} language={selectedLanguage} drawerLanguage={drawerLanguage}/>
          
        </Box>
      </Box>
    </Container>
  );
};

export default BookShelf;
