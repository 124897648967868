import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import './TextEbook.css';
import { useNavigate } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const TextEbook = ({ textArray, onWordSelection, onWordUp, onWordDown, onWordOut, onWordOver, onWordRefsUpdate, onWordSpaceRefsUpdate, page, pageIndexes, onBookGenerated, fontFilledDots, bookData, onTextTouchEnd, onTextTouchStart, onTextTouchOver, bookContent, onTextMouseMove, theme, isBookEnded }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [pageHistory, setPageHistory] = useState([]);
  const [fontSize, setFontSize] = useState();
  const [pageContent, setPageContent] = useState();
  const [isTextGenerated, setIsTextGenerated] = useState(false);
  const [isTextStyled, setIsTextStyled] = useState(false);
  const wordRefs = useRef([]);
  const wordSpaceRefs = useRef([]);

  useEffect(() => {
    if (bookData != undefined) {
      setFontSize(bookData.fontSize);
    }
  }, [bookData]);

  useEffect(() => {
    console.log("isTextStyled: ", isTextStyled);
  }, [isTextStyled]);

  useEffect(() => {
    onWordRefsUpdate(wordRefs);
  }, [wordRefs]);

  useEffect(() => {
    onWordSpaceRefsUpdate(wordSpaceRefs);
  }, [wordSpaceRefs]);

  useEffect(() => {
    if (!bookContent || bookContent.content.length === 0) return;
    setIsTextStyled(false);
    onBookGenerated(false);
    wordRefs.current = [];
    wordSpaceRefs.current = [];
    console.log("useEffect1");
    const parsedHTML = parse(bookContent.content, { replace: wrapAndFilterSpans });

    containerRef.current.style.justifyContent = page.next ? 'flex-start' : 'flex-end';

    setPageContent(parsedHTML);
    //requestAnimationFrame(() => {
      setIsTextGenerated(true);
    //});
  }, [bookContent, theme]);

  useEffect(() => {
    // onBookGenerated(false);
    if (isTextGenerated && wordRefs.current && wordRefs.current.length > 0) {
    //setIsTextStyled(false);
    console.log("useEffect2");
      const wordsOnScreen = wordRefs.current.filter(child => getWordsOnScreen(containerRef.current, child));
      const spacesOnScreen = wordSpaceRefs.current.filter(child => getWordsOnScreen(containerRef.current, child));
      wordsOnScreen.forEach(element => {
        element.style.opacity = 1;
      });
      spacesOnScreen.forEach(element => {
        element.style.opacity = 1;
      });

      const lastWordOnPage = parseInt(wordsOnScreen[wordsOnScreen.length - 1].id);
      const firstWordOnPage = parseInt(wordsOnScreen[0].id);

      const wordOverflowToHide = wordRefs.current.filter(x => parseInt(x.id) < firstWordOnPage || parseInt(x.id) > lastWordOnPage);
      const wordSpacesOverflowToHide = wordSpaceRefs.current.filter(x => parseInt(x.id) < firstWordOnPage || parseInt(x.id) > lastWordOnPage);
      wordOverflowToHide.forEach(element => {
        element.style.opacity = 0;
      });
      wordSpacesOverflowToHide.forEach(element => {
        element.style.opacity = 0;
      });

      pageIndexes({ firstPageIndex: firstWordOnPage, lastPageIndex: lastWordOnPage });
      setIsTextGenerated(false);
      onBookGenerated(true);
      setIsTextStyled(true);
    }
  }, [isTextGenerated]);

  const wrapAndFilterSpans = (node) => {
    if (node.type === 'tag' && node.name === 'span') {
      return (
        <>
          <span style={{
            borderBottom: '2px solid transparent',
            userSelect: "text",
            cursor: "pointer",
            userSelect: "none",
          }}
            {...node.attribs}
            ref={(el) => {
              if (el) {
                wordRefs.current[parseInt(node.attribs.id)] = el;
                const isSentenceTranslated = bookContent.wordValues.find(x => x.id === node.attribs.id)?.isSentenceTranslated;
                const isLightTheme = theme === 'light' || theme === 'white';
                el.style.backgroundColor = isSentenceTranslated ? (isLightTheme ? '#ADD8E6' : '#0F7DA1') : 'transparent';

                if (isSentenceTranslated && theme === 'dark') {
                  el.style.color = 'white';
                } else if (theme === 'dark') {
                  el.style.color = '#AAAAAA'
                } else {
                  el.style.color = 'black'
                }
                el.style.borderBottom = bookContent.wordValues.find(x => x.id === node.attribs.id)?.isWordTranslated ? '2px solid #00B2FF' : '2px solid transparent';
              }
            }}
          >
            {node.children && node.children.map(wrapAndFilterSpans)}
          </span>
          <span ref={(el) => {
            if (el) {
              wordSpaceRefs.current[parseInt(node.attribs.id)] = el;
              const isSentenceTranslated = bookContent.wordValues.find(x => x.id === node.attribs.id)?.isSentenceTranslated;
              const isLightTheme = theme === 'light' || theme === 'white';
              el.style.backgroundColor = isSentenceTranslated && bookContent.wordValues.find(x => x.id === node.attribs.id)?.isLastWordSentence === false ?
                (isLightTheme ? '#ADD8E6' : '#0F7DA1') : 'transparent';
            }
          }}
            id={node.attribs.id}
            style={{ userSelect: "none" }}
          >&nbsp;</span>
        </>
      );
    }
    if (node.name === 'br') {
      return <div style={{ width: '100%' }}></div>
    }
    if (node.children && node.children.length > 0) {
      return React.createElement(
        node.name,
        node.attribs,
        node.children.map(wrapAndFilterSpans).filter(Boolean) // Remove null elements
      );
    }

    return node.data;
  };

  useEffect(() => {
    if (fontFilledDots == undefined) {
      return;
    }
    setFontSize(fontFilledDots);
    setIsTextGenerated(true);

  }, [fontFilledDots]);

  const getWordsOnScreen = (parent, child) => {
    const parentRect = parent.getBoundingClientRect();
    const childRect = child.getBoundingClientRect();
    return (
      childRect.bottom <= parentRect.bottom &&
      childRect.top >= parentRect.top
    );
  };

  const handleWordClick = (e) => {
    if (e.target.textContent === '' || /^\s*$/.test(e.target.textContent)) {
      return;
    }
    if(e.target.nodeName != "SPAN"){
      return;
    }
    const selectionRect = e.target.getBoundingClientRect();
    const position = {
      x: selectionRect.left + window.scrollX + selectionRect.width / 2,
      y: selectionRect.top + window.scrollY + 30,
    };
    const context = {
      word: e.target.textContent.replace(/[^\p{L}\s’]/gu, ''),
      wordId: e.target.id,
      position: position,
      height: e.target.offsetHeight
    }
    onWordSelection(context, e);
  };

  const onMouseUpWord = (e) => {
    onWordUp(e);
  }
  const onTouchStart = (e) => {
    onTextTouchStart(e);
  }

  const onTouchOver = (e) => {
    onTextTouchOver(e);
  }

  const onTouchEnd = (e) => {
    onTextTouchEnd(e);
  }

  const onMouseDownWord = (e) => {
    onWordDown(e);
  }

  const onMouseWordMove = (e) => {
    onTextMouseMove(e);
  }
  const onMouseWordOver = (e) => {
    const selectionRect = e.target.getBoundingClientRect()
    const position = {
      x: selectionRect.left + window.scrollX + selectionRect.width / 2,
      y: selectionRect.top + window.scrollY - 40,
    };
    const context = {
      word: e.target.textContent,
      wordId: e.target.id,
      position: position
    }
    onWordOver(context);
  }

  const onMouseWordOut = (e) => {
    const selectionRect = e.target.getBoundingClientRect()
    const position = {
      x: selectionRect.left + window.scrollX + selectionRect.width / 2,
      y: selectionRect.top + window.scrollY + 30,
    };
    const context = {
      word: e.target.textContent,
      wordId: e.target.id,
      position: position
    }
    onWordOut(context);
  }

  const navigateToHome = () => {
    navigate(`/home`);
  };

  return (
    <>
      <div
        onMouseUp={onMouseUpWord}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchOver}
        onMouseDown={onMouseDownWord}
        onMouseOver={onMouseWordOver}
        onMouseMove={onMouseWordMove}
        onMouseOut={onMouseWordOut}
        onClick={handleWordClick}
        ref={containerRef}
        className="reader-container"
        style={{
          fontFamily: 'Georgia',
          fontSize: `${fontSize}px`,
          width: '80svh',
          visibility: isTextStyled ? 'visible' : 'collapse',
          // visibility: isTextStyled ? 'visible' : 'hidden',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          gap: '1em'
        }}>
        {isBookEnded ? <div style={{display: 'contents', color: theme === 'dark' ? 'white' : 'black'}}>
          <h2>{t('congratulations')}!</h2>
        <span style={{fontSize: '1.2rem'}}>{t('finishBook')} &nbsp;
          <span style={{fontWeight: 'bold'}}>{bookData.title}</span>.&nbsp;
          </span>
          <span>{t('writtenBy')}:&nbsp;
          <span style={{fontWeight: 'bold'}}>{bookData.author}</span>
          </span>
          <span>{t('bookWords')}:&nbsp;
            <span style={{fontWeight: 'bold'}}>{bookData?.totalWordCount}</span>
          </span>
          <button onClick={navigateToHome} style={{background: '#616161', color: 'white',borderRadius: '15px', padding: '5px'}}>{t('backToBooksPage')}</button>
        </div>
         : pageContent}
      </div>
      {!bookContent && 
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw', // Ensures full width on mobile
          height: '100svh', // Ensures full height on mobile
          display: 'grid',
          placeItems: 'center',
        }}
      >
  <CircularProgress sx={{ color: '#00B2FF' }} />
</Box>}
    </>
  );
};

export default TextEbook;
