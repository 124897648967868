import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import axios from '../../components/AxiosConfig';
import { useUser } from '../userContext/UserContext.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Box from '@material-ui/core/Box';
import Checkbox from '@mui/material/Checkbox';
import MainAppBar from '../homePage/AppBar';
import Footer from "../../componentLanding/Footer.jsx";
import CircularProgress from '@mui/material/CircularProgress';
import './DashboardApp.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import { styled } from '@mui/material/styles';
import FooterApp from '../../componentLanding/FooterApp.jsx';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#00B2FF',
  },
  '& .MuiLinearProgress-colorPrimary': {
    backgroundColor: '#b2dfdb',
  },
}));

const DashboardApp = () => {
  const { userData, loading } = useUser();
  const navigate = useNavigate();
  const [wordsReadData, setWordsReadData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [practicesData, setPracticesData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [recentBook, setRecentBook] = useState({
    id: "6685657ce7702f7329ef0b9c",
    title: "Pride and prejudice",
    author: "Jane Austen",
    imageUrl: "https://sahappread.blob.core.windows.net/happread/6685657ce7702f7329ef0b9c.webp",
    firstBook: true,
  });
  const [isDefaultBook, setIsDefaultBook] = useState(true); 
  const [hasWordToPractice, setHasWordToPractice] = useState(false); 
  const [readingProgress, setReadingProgress] = useState(0);
  const [loadingRecentBook, setLoadingRecentBook] = useState(true);

  useEffect(() => {
    const fetchWordsStatistics = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/practice/wordStatistics`);
        const data = response.data;
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const wordsRead = daysOfWeek.map(day => data[day] || 0);
        setWordsReadData(wordsRead);
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    const fetchPracticesStatistics = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/practice/practiceStatistics`);
        const data = response.data;
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const practices = daysOfWeek.map(day => data[day] || 0);
        setPracticesData(practices);
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    const fetchRecentBook = async () => {
      setLoadingRecentBook(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Book/recentbook`);
        const bookData = response.data;

        if (bookData?.title && bookData?.author && bookData?.imageUrl) {
          setRecentBook(bookData);
          const hasWordToPractice = await axios.get(`${process.env.REACT_APP_API_URL}/api/practice/hasWordsToPractice/${bookData.id}`);
          setHasWordToPractice(hasWordToPractice.data);
          setIsDefaultBook(false);

          const progress = bookData.currentPosition && bookData.currentPosition > -1 && bookData.totalWordCount
            ? (bookData.currentPosition / bookData.totalWordCount) * 100
            : 0;
          setReadingProgress(progress);
        }
      } catch (error) {
        console.error("Error fetching recent book:", error);
      } finally {
        setLoadingRecentBook(false);
      }
    };

    fetchWordsStatistics();
    fetchPracticesStatistics();
    fetchRecentBook();
  }, []);

  const chartData = {
    labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    datasets: [
      {
        label: 'Words Read',
        data: wordsReadData,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
      {
        label: 'Words Practiced',
        data: practicesData,
        backgroundColor: 'rgba(153,102,255,0.4)',
        borderColor: 'rgba(153,102,255,1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <MainAppBar userData={userData}/>
      <div className="bg-[#f4e7d3] min-h-screen flex flex-col items-center">
        <div className="w-full max-w-3xl p-4" style={{ background: '#f3f4f6', borderRadius: '15px', marginTop: '20px' }}>
          <section className="my-6">
            <h2 className="text-2xl font-semibold text-center" style={{ fontSize: '2.0rem', fontWeight: 'bold' }}>{recentBook?.firstBook ? "Begin reading" : "Recently read"}</h2>
            
            {/* Loading bar centered */}
            {loadingRecentBook ? (
              <div 
                className="flex justify-center items-center" 
                style={{
                  width: '100%',
                  height: '200px',
                }}
              >
                <CustomLinearProgress style={{ width: '5rem' }} />
              </div>
            ) : (
              <div 
                className="bg-[#2ac59c] p-4 rounded-md my-4 text-black flex items-center md:mx-auto md:w-1/2"
              >
                <img
                  src={recentBook.imageUrl}
                  alt={`${recentBook.title} cover`}
                  style={{ height: '150px', width: '150px', borderRadius: '10px', marginRight: '16px' }}
                />
                <div>
                  <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{recentBook.title}</span>
                  <p>{recentBook.author}</p>
                  <div className="h-2 w-full rounded-full mt-2" style={{ backgroundColor: '#add8e6' }}>
                    <div className="h-full rounded-full" style={{ width: `${readingProgress}%`, backgroundColor: '#00b2ff' }}></div>
                  </div>
                  <button
                    className="mt-3 px-4 py-2 bg-[#00b2ff] text-white rounded-md hover:bg-[#008fce] transition-colors"
                    style={{ background: '#00b2ff', borderRadius: '10px', fontWeight: 'bold' }}
                    onClick={() => navigate(`/reader/${recentBook.id}`)}
                  >
                    {isDefaultBook? "Start Reading" : "Continue Reading"}
                  </button>
                  {!isDefaultBook && hasWordToPractice && (
                    <button
                      className="mt-3 px-4 py-2 bg-[#00b2ff] text-white rounded-md hover:bg-[#008fce] transition-colors"
                      style={{ background: 'rgba(153,102,255,1)', borderRadius: '10px', fontWeight: 'bold', marginLeft: '10px' }}
                      onClick={() => navigate(`/flashcards/${recentBook.id}`)}
                    >
                      Start Practice
                    </button>
                  )}
                </div>
              </div>
            )}
          </section>
          <section className="my-6">
            <h2 className="text-2xl font-semibold text-center">Daily Learning Chart</h2>
            <p className="text-center" style={{marginTop: '10px', fontSize: '1.1rem'}}>Track your progress daily</p>
            <div className="w-full md:w-3/4 lg:w-1/2 mx-auto my-6" style={{marginTop: '20px'}}>
              <div className="chart-div">
                <Bar data={chartData} options={options} />
              </div>
            </div>
            <label className="flex items-center justify-center my-4">
              <Checkbox defaultChecked sx={{
                  color: '#00b2ff',
                  '&.Mui-checked': {
                    color: '#00b2ff',
                  },
                }}/>
              <span>Send me a daily reminder email to help me maintain my reading streak.</span>
            </label>
          </section>
        </div>
      </div>
      <FooterApp />
    </>
  );
};

export default DashboardApp;
