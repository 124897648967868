import React, { useState, useEffect, useRef } from 'react';
import CircleLoader from "react-spinners/ClipLoader";
import './WordExplanation.css'
import CloseIcon from '@material-ui/icons/Close';
import { Scrollbar } from 'react-scrollbars-custom';
import UnlockIcon from '../../icons/UnlockIcon';
import ArrowRightTopIcon from '../../icons/ArrowRightTopIcon';
import { useTranslation } from 'react-i18next';

const WordExplanation = ({ wordContext, wordContextDescription, onExplanationWordClose, wordContextPosition, currentTheme, onShowPremium, reachLimit }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const explanationRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };
    window.addEventListener('resize', handleResize);
    const isWordContextBelow = wordContextPosition.y > window.innerHeight* 0.55;
    if(explanationRef.current && window.innerWidth < 900 && isWordContextBelow) {
      explanationRef.current.style.bottom = "68%"
    } else if(explanationRef.current && window.innerWidth < 900 && !isWordContextBelow){
      explanationRef.current.style.bottom = "2%"
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const onClose = () =>{
    onExplanationWordClose();
  }

  const showPremium = () => {
    onExplanationWordClose();
    onShowPremium(true);
  }

  return (
    <div ref={explanationRef} className={`explanationContent ${isMobile ? 'mobile' : 'desktop'}`} style={{
      background: currentTheme === 'white' ? 'white' : (currentTheme === 'light' ? '#DBD6C5' : '#565656'),
      color: currentTheme === 'dark' ? 'white' : 'black'
      }}>
      <div className='explanationContent__header'>{t('explanationFor')} <span style={{fontWeight: 'bold'}}>{wordContext}</span> {t('inContext')}</div>
      <div className="closeWordContext" onClick={onClose}>
      <CloseIcon />
      </div>
      {isMobile && <div className="explanationContent__description mobile">
            {!wordContextDescription && reachLimit === false && <div style={{textAlign: 'center'}}><CircleLoader color="#00B2FF" size="1.5rem" /></div>}
            <Scrollbar 
              style={{ height: '20.5svh', width: '87.0svw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{paddingRight: '5px'}}>
                {wordContextDescription}
                </div>
                {reachLimit && (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span style={{ color: '#00B2FF', textDecoration: 'underline', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                      <UnlockIcon style={{ height: '20px', width: '20px', marginRight: '5px' }} />
                      <span onClick={showPremium} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                        Unlock with premium <ArrowRightTopIcon style={{ marginLeft: '5px' }} />
                      </span>
                    </span>
                  </div>
                )}

          </Scrollbar>

      </div>}

          {!isMobile && <div className="explanationContent__description desktop">
              {!wordContextDescription && reachLimit === false && <div style={{ textAlign: 'center' }}><CircleLoader color="#00B2FF" size="1.5rem" /></div>}
              {wordContextDescription}
              {reachLimit &&
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ color: '#00B2FF', textDecoration: 'underline', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={showPremium}>
                          <UnlockIcon style={{ height: '20px', width: '20px', marginRight: '5px' }} />
                          Unlock with premium
                          <ArrowRightTopIcon style={{ height: '20px', width: '20px', marginLeft: '5px' }} />
                      </span>
                  </div>
              }
      </div>}

    </div>

    );
};

export default WordExplanation;
