import React from 'react';
import './PremiumTable.css';
import { CheckIcon, CloseIcon } from '@mantine/core';

const PremiumTable = ({ plans, featuresName }) => {
    return (
        <div className="table-container">
            <table className="subscription-table">
                <thead>
                    <tr>
                        <th>{featuresName}</th>
                        {plans.map(plan => (
                            <th key={plan.name}>{plan.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {plans[0].featuresTable.map((feature, index) => (
                        <tr key={index}>
                            <td data-label="Feature" style={{fontSize: '1.3rem'}}>{feature.name}</td>
                            {plans.map(plan => (
                                <td key={plan.name} data-label={plan.name} style={{fontSize: '1.3rem'}}>
                                    {typeof plan.featuresTable[index].included === 'boolean' ? (
                                        plan.featuresTable[index].included ? 
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <CheckIcon style={{ color: 'green', height: '25px', width: '25px', marginRight: '5px' }} />
                                        </div>

                                        : 
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <CloseIcon size={27} style={{color: 'red', marginRight: '5px'}}/>
                                        </div>
                                        
                                    ) : (
                                        <span style={{fontWeight: plan.name === 'Premium'? 'bold' : 'normal', fontSize: '1.3rem'}}>{plan.featuresTable[index].included}</span>
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="cards-container">
                {plans
                    .filter(plan => plan.name === 'Premium')
                    .map(plan => (
                        <div key={plan.name} className="plan-card">
                            {/* <h2 style={{textAlign: 'center', color: '#00B2FF', fontSize: '2.2rem'}}>{plan.name}</h2> */}
                            <ul>
                                {plan.featuresTable.map((feature, index) => (
                                    <li key={index} style={{textAlign: 'left', color: 'black', fontSize: '15px'}}>
                                    <CheckIcon style={{color: 'green', height: '25px', width: '25px', marginRight: '5px'}}/>
                                    {typeof feature.included !== 'boolean' ? 
                                        <span>
                                            {feature.name} <strong>[{feature.included}]</strong>
                                        </span> : 
                                        <strong>{feature.name}</strong>}
                                </li>
                                ))}
                            </ul>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default PremiumTable;
