import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import MainAppBar from '../homePage/AppBar';
import axios from '../../components/AxiosConfig';
import Box from '@material-ui/core/Box';
import BarLoader from 'react-spinners/BarLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import defaultImage from '../../images/book-default-min.png';
import useSignalRConnectionBookDescription from "../../hooks/useSginalRConnectionBookDescription";
import { bookLanguages } from '../../constants/global.js';
import Flag from 'react-flagkit';
import { useTranslation } from 'react-i18next';
import UnlockPremiumBookIcon from "../../icons/UnlockPremiumBookIcon";
import ButtonRead from '../../componentLanding/ButtonRead.jsx';
import CircleLoader from "react-spinners/ClipLoader";
import DeleteIcon from '@material-ui/icons/Delete';
import { Modal, Button } from 'react-bootstrap';
import './BookDetails.css';
import BasicBookChart from '../../icons/BasicBookChart';
import AdvancedBookChart from '../../icons/AdvancedBookChart';
import IntermediateBookChart from '../../icons/IntermediateBookChart';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../userContext/UserContext.js';

const BookDetails = () => {
  const { t } = useTranslation();
  const { bookId } = useParams();
  const [bookDetails, setBookDetails] = useState();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [bookDetailsId, setBookDetailsId] = useState('');
  const [isLock, setIsLock] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  const { bookDescription, initialized, clearBookDescription } = useSignalRConnectionBookDescription(bookDetailsId, bookId);
  const { userData, loading } = useUser();
  const languageToCountryCode = {
    English: 'US',
    Spanish: 'ES',
    Portuguese: 'PT',
    French: 'FR',
    German: 'DE',
    Italian: 'IT',
    Dutch: 'NL',
    Hungarian: 'HU',
    Greek: 'GR',
    Swedish: 'SE',
    Danish: 'DK',
    Finnish: 'FI',
    Polish: 'PL',
    Russian: 'RU',
    Chinese: 'CN',
    Japanese: 'JP',
    Korean: 'KR',
    Arabic: 'SA',
    Norwegian: 'NO',
    Turkish: 'TR',
    Czech: 'CZ',
    Slovak: 'SK',
    Hebrew: 'IL',
    Hindi: 'IN',
    Bengali: 'BD',
    Thai: 'TH',
    Vietnamese: 'VN',
    Ukrainian: 'UA',
    Romanian: 'RO',
    Bulgarian: 'BG',
    Indonesian: 'ID',
    Filipino: 'PH',
  };

  useEffect(() => {
    if (initialized) {
      fetchData();
    }
  }, [initialized, bookId]);

  const fetchData = async () => {
    try {
      const newBookDetailsId = `id-${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
      setBookDetailsId(newBookDetailsId);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Book/${bookId}`);
      const data = response.data;
      setBookDetails(data);
      setIsLock(data.isLocked);
      if (!data.imageUrl) {
        setImageLoaded(true);
      }
      setIsLoading(false);

      await axios.get(`${process.env.REACT_APP_API_URL}/api/Book/${bookId}/description/${newBookDetailsId}`);
    } catch (error) {
      console.error('Failed to fetch books:', error);
      setIsLoading(false);
    }
  };

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  const handleRemove = () => {
    setOpenDialog(true);
  };

  const handleConfirmRemove = async () => {
    setIsDeleting(true);
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/Book/${bookDetails.id}`);
      setOpenDialog(false);
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Failed to remove the book', error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCancelRemove = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <MainAppBar userData={userData}/>
      {bookDetails && (
        <div
          className="book-details-container"
          style={{ visibility: !imageLoaded && bookDetails ? 'collapse' : 'visible' }}
        >
          <div>
            <div style={{ marginTop: '10px' }} key={bookDetails.id}>
              <h2 style={{ fontSize: '2.5rem', fontWeight: 'bold', marginBottom: '10px' }}>
                {bookDetails.title}
              </h2>
              <div style={{ position: 'relative', display: 'inline-block' }}>
                {!imageLoaded && (
                  <Box
                    style={{
                      width: '300px',
                      height: '351px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <BarLoader size={35} color="#00B2FF" />
                  </Box>
                )}
                {bookDetails.imageUrl && (
                  <img
                    src={bookDetails.imageUrl}
                    alt={bookDetails.title}
                    onLoad={handleImageLoaded}
                    style={{ width: '300px', height: '300px', borderRadius: '8px' }}
                  />
                )}
                {!bookDetails.imageUrl && imageLoaded && (
                  <img
                    src={defaultImage}
                    alt="Default Book"
                    style={{ width: '300px', height: '300px', borderRadius: '8px' }}
                  />
                )}
                {bookDetails.isDeleteAvailable && ( // Conditional rendering based on isDeleteAvailable
                  <button
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      backgroundColor: 'red',
                      border: 'none',
                      borderRadius: '50%',
                      padding: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={handleRemove}
                  >
                    <DeleteIcon style={{ color: 'white' }} />
                  </button>
                )}
              </div>
              <div className="book-detail-author">{bookDetails.author}</div>
              {!isLock && (
                <Link
                  to={`/reader/${bookDetails.id}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <ButtonRead className="text-white" white>
                    {isLoading ? <ClipLoader size={20} color="#fff" /> : t('readBook')}
                  </ButtonRead>
                </Link>
              )}
              {isLock && (
                <Link
                  to={`/subscription/premium`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <ButtonRead className="text-white" white>
                    {t('unlockUnlimited')}
                  </ButtonRead>
                </Link>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '0px',
                }}
              >
                <span style={{ fontWeight: 'bold', marginRight: '8px' }}>
                  {t('language')}:
                </span>
                <Flag
                  country={
                    languageToCountryCode[bookDetails.language] || 'US'
                  }
                  style={{
                    width: '1.5rem',
                    height: '1.5rem',
                    borderRadius: '5px',
                  }}
                />
              </div>
              {bookDetails.isBasicBook && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '-40px',
                    }}
                  >
                    <span
                      style={{ marginLeft: '40px', marginTop: '40px', fontWeight: 'bold' }}
                    >
                      {t('languageLevel')}:
                    </span>
                    {bookDetails.languageLevel === 'basic' && (
                      <div
                        style={{
                          width: '110px',
                          height: '70px',
                          overflow: 'hidden',
                          marginLeft: '-45px',
                        }}
                      >
                        <BasicBookChart />
                      </div>
                    )}
                    {bookDetails.languageLevel === 'intermediate' && (
                      <div
                        style={{
                          width: '110px',
                          height: '70px',
                          overflow: 'hidden',
                          marginLeft: '-45px',
                        }}
                      >
                        <IntermediateBookChart />
                      </div>
                    )}
                    {bookDetails.languageLevel === 'advanced' && (
                      <div
                        style={{
                          width: '110px',
                          height: '70px',
                          overflow: 'hidden',
                          marginLeft: '-45px',
                        }}
                      >
                        <AdvancedBookChart />
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: '5px' }}>
                    <span style={{ fontWeight: 'bold' }}>{t('genres')}: </span>
                    <span style={{ color: '#00B2FF', fontWeight: 'bold' }}>
                      {bookDetails.genreMain + ', ' + bookDetails.genreInner}
                    </span>
                  </div>
                </>
              )}
              <hr
                style={{
                  color: '#00B2FF',
                  marginLeft: '5%',
                  marginRight: '5%',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              />
              {bookDetails.isBasicBook && (
                <p
                  style={{
                    textAlign: 'left',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    paddingBottom: '15px',
                  }}
                >
                  {!bookDescription && (
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <BarLoader size={35} color="#00B2FF" />
                    </Box>
                  )}
                  {bookDescription}
                </p>
              )}
              {!bookDetails.isBasicBook && (
                <p
                  style={{
                    textAlign: 'center',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    paddingBottom: '15px',
                  }}
                >
                  {bookDetails.ownDescription}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      {!bookDetails && !imageLoaded && (
        <div style={{ position: 'relative', height: '80vh' }}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircleLoader color="#00B2FF" size="2.5rem" />
          </div>
        </div>
      )}
      {/* Confirmation Modal */}
      <Modal show={openDialog} onHide={handleCancelRemove} centered>
        <Modal.Header closeButton>
          <Modal.Title>Remove book</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you really want to remove "{bookDetails?.title}"?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelRemove}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmRemove} disabled={isDeleting}>
            {isDeleting ? <ClipLoader size={15} color="#fff" /> : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Success Modal */}
      <Modal show={showSuccessModal} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>Book Deleted</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>The book has been deleted successfully.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate('/home')}>
            Go to Books
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BookDetails;
