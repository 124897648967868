import { curve, heroBackground, robot } from "../assets";
import mobilePresentation from "../assets/mobile_presentation.gif"
import desktopPresentation from "../assets/desktop_presentation.gif"
import Button from "./Button";
import Section from "./Section";
import { BackgroundCircles, BottomLine, Gradient } from "./design/Hero";
import { heroIcons } from "../constantsLanding";
import { ScrollParallax } from "react-just-parallax";
import { useRef } from "react";
import Generating from "./Generating";
import Notification from "./Notification";
import CompanyLogos from "./CompanyLogos";
import styled from 'styled-components';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const Curve = styled.div`
  width: 100%;
  height: 12px;
  background: linear-gradient(to right, #00f, #3399ff, #66ccff, #99ccff, #cceeff);
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  transform: rotate(0deg);

  @media (max-width: 768px) {
    height: 8px;
  }
`;

const GradientCurve = () => {
  return <Curve />;
};



const Hero = () => {
  const parallaxRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  const nav = () => {
    navigate('/home');
  }
  
  // Check for screen width changes
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768); // Mobile if screen width is less than 768px (Tailwind's md breakpoint)
    };

    checkScreenSize(); // Check on component mount
    window.addEventListener("resize", checkScreenSize); // Add listener for resize events

    return () => window.removeEventListener("resize", checkScreenSize); // Cleanup on unmount
  }, []);
  return (
    <Section
      className="pt-[12rem] -mt-[5.25rem]"
      crosses
      crossesOffset="lg:translate-y-[5.25rem]"
      customPaddings
      id="hero"
    >
      <div className="container relative" ref={parallaxRef}>
        <div className="relative z-1 max-w-[62rem] mx-auto text-center mb-[3.875rem] md:mb-20 lg:mb-[6.25rem] text-black">
          <h1 className="h1 mb-6">
            {/* Explore the Possibilities of&nbsp;AI&nbsp;Chatting with {` `} */}
            Explore reading with {` `}
            <span className="inline-block relative">
             HappRead{" "}
              {/* <img
                src={curve}
                className="absolute top-full left-0 w-full xl:-mt-2"
                width={624}
                height={28}
                alt="Curve"
              /> */}
              <GradientCurve />
            </span>
          </h1>
          <p className="body-1 max-w-3xl mx-auto mb-6 text-n-2 lg:mb-8 text-black">
          Unleash the power of AI with HappRead. Elevate your reading experience using HappRead, the AI-powered app
          </p>
          <Button onClick={nav} white>
            Start learning
          </Button>
        </div>
        <div className="relative max-w-[23rem] mx-auto md:max-w-5xl xl:mb-24">
        <div className="relative z-1 p-0.5 rounded-2xl" style={{ backgroundColor: '#96E4FD' }}>
            <div className="relative bg-n-8 rounded-[1rem]">
            <div className={`h-[${isMobile ? '2rem' : '1.4rem'}] rounded-t-[0.9rem]`} style={{ backgroundColor: '#96E4FD' }}/> 

            <div className={`aspect-${isMobile ? '[40/40]' : '[33/40]'} md:aspect-[688/490] lg:aspect-[1024/490] rounded-b-[0.9rem] overflow-hidden`}>
              <img
                src={isMobile ? mobilePresentation : desktopPresentation}
                className="w-full h-full object-cover rounded-[1rem]"
                alt="AI"
              />
            </div>

            </div>

            {/* <Gradient /> */}
          </div>
          <div className="absolute -top-[54%] left-1/2 w-[234%] -translate-x-1/2 md:-top-[46%] md:w-[138%] lg:-top-[104%]">
            <img
              src={heroBackground}
              className="w-full"
              width={1440}
              height={1800}
              alt="hero"
              style={{ opacity: 0.05 }} 
            />
          </div>

          <BackgroundCircles />
        </div>

        {/* <CompanyLogos className="hidden relative z-10 mt-20 lg:block" /> */}
      </div>

      <BottomLine />
    </Section>
  );
};

export default Hero;
