const bookLanguages = [
    { name: 'English', native: 'English', flag: 'US' },
    { name: 'Spanish', native: 'Español', flag: 'ES' },
    { name: 'Portuguese', native: 'Português', flag: 'PT' },
    { name: 'French', native: 'Français', flag: 'FR' },
    { name: 'German', native: 'Deutsch', flag: 'DE' },
    { name: 'Italian', native: 'Italiano', flag: 'IT' },
    { name: 'Dutch', native: 'Nederlands', flag: 'NL' },
    { name: 'Hungarian', native: 'Magyar', flag: 'HU' },
    { name: 'Greek', native: 'Ελληνικά', flag: 'GR' },
    { name: 'Swedish', native: 'Svenska', flag: 'SE' },
    { name: 'Danish', native: 'Dansk', flag: 'DK' },
    { name: 'Finnish', native: 'Suomi', flag: 'FI' }
  ];
  
  const gptLanguages = [
    { name: 'English', native: 'English', flag: 'US' },
    { name: 'Chinese', native: '中文', flag: 'CN' },
    { name: 'Hindi', native: 'हिन्दी', flag: 'IN' },
    { name: 'Spanish', native: 'Español', flag: 'ES' },
    { name: 'French', native: 'Français', flag: 'FR' },
    { name: 'Arabic', native: 'العربية', flag: 'SA' },
    { name: 'Bengali', native: 'বাংলা', flag: 'BD' },
    { name: 'Russian', native: 'Русский', flag: 'RU' },
    { name: 'Portuguese', native: 'Português', flag: 'PT' },
    { name: 'Indonesian', native: 'Bahasa Indonesia', flag: 'ID' },
    { name: 'Urdu', native: 'اردو', flag: 'PK' },
    { name: 'Japanese', native: '日本語', flag: 'JP' },
    { name: 'Swahili', native: 'Kiswahili', flag: 'KE' },
    { name: 'German', native: 'Deutsch', flag: 'DE' },
    { name: 'Korean', native: '한국어', flag: 'KR' },
    { name: 'Vietnamese', native: 'Tiếng Việt', flag: 'VN' },
    { name: 'Tamil', native: 'தமிழ்', flag: 'IN' },
    { name: 'Turkish', native: 'Türkçe', flag: 'TR' },
    { name: 'Italian', native: 'Italiano', flag: 'IT' },
    { name: 'Thai', native: 'ไทย', flag: 'TH' },
    { name: 'Gujarati', native: 'ગુજરાતી', flag: 'IN' },
    { name: 'Marathi', native: 'मराठी', flag: 'IN' },
    { name: 'Persian', native: 'فارسی', flag: 'IR' },
    { name: 'Polish', native: 'Polski', flag: 'PL' },
    { name: 'Dutch', native: 'Nederlands', flag: 'NL' },
    { name: 'Hausa', native: 'Hausa', flag: 'NG' },
    { name: 'Romanian', native: 'Română', flag: 'RO' },
    { name: 'Javanese', native: 'Basa Jawa', flag: 'ID' },
    { name: 'Tagalog', native: 'Tagalog', flag: 'PH' },
    { name: 'Cebuano', native: 'Cebuano', flag: 'PH' },
    { name: 'Malay', native: 'Bahasa Melayu', flag: 'MY' },
    { name: 'Yoruba', native: 'Yorùbá', flag: 'NG' },
    { name: 'Amharic', native: 'አማርኛ', flag: 'ET' },
    { name: 'Odia', native: 'ଓଡ଼ିଆ', flag: 'IN' },
    { name: 'Kannada', native: 'ಕನ್ನಡ', flag: 'IN' },
    { name: 'Burmese', native: 'ဗမာစာ', flag: 'MM' },
    { name: 'Swedish', native: 'Svenska', flag: 'SE' },
    { name: 'Finnish', native: 'Suomi', flag: 'FI' },
    { name: 'Serbian', native: 'Српски', flag: 'RS' },
    { name: 'Czech', native: 'Čeština', flag: 'CZ' },
    { name: 'Afrikaans', native: 'Afrikaans', flag: 'ZA' },
    { name: 'Croatian', native: 'Hrvatski', flag: 'HR' },
    { name: 'Hungarian', native: 'Magyar', flag: 'HU' },
    { name: 'Bulgarian', native: 'Български', flag: 'BG' },
    { name: 'Slovak', native: 'Slovenčina', flag: 'SK' },
    { name: 'Danish', native: 'Dansk', flag: 'DK' },
    { name: 'Norwegian', native: 'Norsk', flag: 'NO' },
    { name: 'Hebrew', native: 'עברית', flag: 'IL' },
    { name: 'Latvian', native: 'Latviešu', flag: 'LV' },
    { name: 'Lithuanian', native: 'Lietuvių', flag: 'LT' },
    { name: 'Slovenian', native: 'Slovenščina', flag: 'SI' },
    { name: 'Albanian', native: 'Shqip', flag: 'AL' },
    { name: 'Icelandic', native: 'Íslenska', flag: 'IS' },
    { name: 'Estonian', native: 'Eesti', flag: 'EE' },
    { name: 'Maltese', native: 'Malti', flag: 'MT' },
    { name: 'Irish', native: 'Gaeilge', flag: 'IE' },
    { name: 'Luxembourgish', native: 'Lëtzebuergesch', flag: 'LU' },
    { name: 'Basque', native: 'Euskara', flag: 'ES' },
    { name: 'Macedonian', native: 'Македонски', flag: 'MK' },
    { name: 'Galician', native: 'Galego', flag: 'ES' },
    { name: 'Kazakh', native: 'Қазақша', flag: 'KZ' },
    { name: 'Armenian', native: 'Հայերեն', flag: 'AM' },
    { name: 'Mongolian', native: 'Монгол', flag: 'MN' },
    { name: 'Nepali', native: 'नेपाली', flag: 'NP' },
    { name: 'Georgian', native: 'ქართული', flag: 'GE' },
    // { name: 'Azerbaijani', native: 'Azərbaycan dili', flag: 'AZ' },
    { name: 'Kurdish', native: 'Kurdî', flag: 'IQ' },
    { name: 'Pashto', native: 'پښتو', flag: 'AF' },
    { name: 'Uyghur', native: 'ئۇيغۇرچە', flag: 'CN' },
    { name: 'Tajik', native: 'Тоҷикӣ', flag: 'TJ' },
    { name: 'Tatar', native: 'татарча', flag: 'RU' },
    { name: 'Kyrgyz', native: 'Кыргызча', flag: 'KG' },
    { name: 'Kinyarwanda', native: 'Ikinyarwanda', flag: 'RW' },
    { name: 'Maori', native: 'Māori', flag: 'NZ' },
    { name: 'Chichewa', native: 'Chichewa', flag: 'MW' },
    { name: 'Haitian Creole', native: 'Kreyòl Ayisyen', flag: 'HT' },
    { name: 'Sinhala', native: 'සිංහල', flag: 'LK' },
    { name: 'Somali', native: 'Soomaaliga', flag: 'SO' },
    { name: 'Igbo', native: 'Igbo', flag: 'NG' },
    { name: 'Bosnian', native: 'Bosanski', flag: 'BA' },
    { name: 'Zulu', native: 'isiZulu', flag: 'ZA' },
    { name: 'Xhosa', native: 'isiXhosa', flag: 'ZA' },
    { name: 'Corsican', native: 'Corsu', flag: 'FR' },
    { name: 'Frisian', native: 'Frysk', flag: 'NL' },
    { name: 'Scots Gaelic', native: 'Gàidhlig', flag: 'GB' },
    { name: 'Sesotho', native: 'Sesotho', flag: 'LS' },
    { name: 'Shona', native: 'chiShona', flag: 'ZW' },
    { name: 'Sindhi', native: 'سنڌي', flag: 'PK' },
    { name: 'Esperanto', native: 'Esperanto', flag: 'EU' },
    { name: 'Latin', native: 'Latine', flag: 'VA' },
    { name: 'Samoan', native: 'Gagana Samoa', flag: 'WS' }
  ];

export { bookLanguages, gptLanguages }