// ErrorModal.js
import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ErrorContext } from './ErrorContext';
import { FaExclamationTriangle } from 'react-icons/fa';
import ArrowRightTopIcon from '../../icons/ArrowRightTopIcon';
import { useTranslation } from 'react-i18next';

const ErrorModal = () => {
  const { t } = useTranslation();
  const { error, setError } = useContext(ErrorContext);

  const handleClose = () => {
    setError(null);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleHome = () => {
    window.location.href = '/home';
  };

  return (
    <Modal show={!!error} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: '1.5rem', display: 'flex', alignItems: 'center' }}>
          <FaExclamationTriangle style={{ color: '#dc3545', marginRight: '10px' }} />
                  <span>{t('somethingWentWrong')}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
                  {t('pleaseTryRefreshInfo')}
                  {t('ifProblemPersists')}{' '}
          <a href="/support" style={{ color: '#00B2FF', textDecoration: 'underline', display: 'inline-flex', alignItems: 'center' }}>
                      {t('contactOurSupportTeam')} <ArrowRightTopIcon style={{ marginLeft: '5px' }} />
          </a>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleRefresh}>
                  {t('refreshPage')}
        </Button>
        <Button variant="primary" onClick={handleHome}>
                  {t('homePage')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
