import React, { useState, useEffect } from 'react';
import './CookieConsent.css';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);
 
    const handleClose = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <div className="cookie-consent-content">
      <button className="cookie-consent-close" onClick={handleClose}>×</button>
        <p>The service uses cookies for the proper provision of services, as well as for statistical and advertising purposes. <a href="/privacy-policy">[ More information ]</a></p>
        
      </div>
    </div>
  );
};

export default CookieConsent;
