import React, { useState } from 'react';
import { FaArrowLeft, FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import axios from '../../components/AxiosConfig';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import './ChangePassword.css';
import { useTranslation } from 'react-i18next';

const ChangePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleBackClick = () => {
    navigate('/home');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    if (newPassword.length < 6) {
      setError('Password must be at least 6 characters long');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords are not the same');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/keycloak/resetpassword`, {
        currentPassword,
        newPassword,
      });
    
      if (response.status !== 200) {
        throw new Error('Failed to change password');
      }
    
      setSuccess(true);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setTimeout(() => setSuccess(false), 3000);
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data);
      } else {
        setError('Failed to change password');
      }
      setTimeout(() => setError(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-container">
      <div className="header">
        <button className="back-button" onClick={handleBackClick}>
          <FaArrowLeft />
        </button>
        <h1 style={{ color: '#333', fontSize: '1.5em', margin: '0', borderBottom: '1px solid #ddd' }}>
          {t('changePassword')}
        </h1>
      </div>
      {success && (
        <div className="bottom-alert">
          <Alert variant="success" onClose={() => setSuccess(false)} dismissible>
            {t('passwordChangedSuccess')}!
          </Alert>
        </div>
      )}
      {error && (
        <div className="bottom-alert">
          <Alert variant="danger" onClose={() => setError('')} dismissible>
            {error}
          </Alert>
        </div>
      )}
      <form className="form-container" onSubmit={handleSubmit}>
        <label>
          {t('currentPassword')}
          <div className="password-container">
            <input
              type={showCurrentPassword ? 'text' : 'password'}
              className="input-field"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <button
              type="button"
              className="password-toggle"
              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
            >
              {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </label>
        <label>
          {t('newPassword')}
          <div className="password-container">
            <input
              type={showNewPassword ? 'text' : 'password'}
              className="input-field"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <button
              type="button"
              className="password-toggle"
              onClick={() => setShowNewPassword(!showNewPassword)}
            >
              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </label>
        <label>
          {t('confirmNewPassword')}
          <div className="password-container">
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              className="input-field"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button
              type="button"
              className="password-toggle"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </label>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : t('changePassword')}
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
