import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../src/locales/en/translation.json';
import translationPL from '../src/locales/pl/translation.json';
import translationES from '../src/locales/es/translation.json';
import translationPT from '../src/locales/pt/translation.json';
import translationTR from '../src/locales/tr/translation.json';
import translationRU from '../src/locales/ru/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  pl: {
    translation: translationPL,
  },
  es: {
    translation: translationES,
  },
  pt: {
    translation: translationPT,
  },
  tr: {
    translation: translationTR,
  },
  ru: {
    translation: translationRU,
  },
};
const languageShortcuts = {
  'Polski': 'pl',
  'English': 'en',
  'Russian': 'ru',
  'Spanish': 'es',
  'Portuguese': 'pt',
  'Turkish': 'tr'
};

const initializeI18n = () => {
      i18n
      .use(initReactI18next)
      .init({
        resources,
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false
        },
      });
};
initializeI18n();


export default initializeI18n;
