import React, { useEffect, useState, useRef } from 'react';
import './BookSettings.css';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close'; // Import Close icon
import axios from '../../components/AxiosConfig';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { styled } from "@mui/material/styles";
import { bookLanguages, gptLanguages } from '../../constants/global.js';
import BookSettingsLanguageAutocomplete from '../bookSettingsLanguageAutocomplete/BookSettingsLanguageAutocomplete';
import { useTranslation } from 'react-i18next';
import { useUser } from '../userContext/UserContext.js';

const CustomToggleButton = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "#00B2FF",
    backgroundColor: '#00ff00'
  }
});

const BookSettings = ({ onFontSizeChange, bookData, onThemeChange, showBookSettings, onClose, onUserDataLoad }) => {
  const [selectedWordLanguage, setSelectedWordLanguage] = useState(null);
  const [selectedExplanationLanguage, setSelectedExplanationLanguage] = useState(null);
  const [theme, setTheme] = useState('');
  const [translationLanguage, setTranslationLanguage] = useState(null);
  const { t } = useTranslation();
  const totalDots = 8;
  const [filledDots, setFilledDots] = useState(null);
  const settingsRef = useRef(null);
  const { userData, loading } = useUser();
  const staticDictionary = {
    1: 10,
    2: 12,
    3: 16,
    4: 18,
    5: 20,
    6: 22,
    7: 24,
    8: 28,
  };

  useEffect(() => {
    if (settingsRef.current) {
      const rect = settingsRef.current.getBoundingClientRect();

      if (rect.right > window.outerWidth) {
        const outScreenWidth = rect.right - window.outerWidth;
        settingsRef.current.style.left = `${settingsRef.current.offsetLeft - outScreenWidth - 15}px`;
      }
    }
  }, [bookData]);

  useEffect(() => {
    if (bookData) {
      const fetchUserData = async () => {
        try {
          setSelectedWordLanguage(gptLanguages.find(x => x.name === userData.translationLanguage));
          setSelectedExplanationLanguage(gptLanguages.find(x => x.name === userData.explanationLanguage));
          onUserDataLoad(userData);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
      fetchUserData();
      setSettings();
    }
  }, [bookData]);

  const setSettings = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Book/settings/${bookData.id}`);
      const data = response.data;
      setFilledDots(parseInt(Object.keys(staticDictionary).find(key => staticDictionary[key] === data.fontSize)))
      onThemeChange(data.theme);
      setTheme(data.theme);
    } catch (error) {
      console.error('Failed to fetch settings:', error);
    }
  };

  const increaseFontSize = () => {
    if (filledDots < totalDots) {
      setFilledDots(filledDots + 1);
      onFontSizeChange(staticDictionary[filledDots + 1]);
      axios.put(`${process.env.REACT_APP_API_URL}/api/Book/${bookData.id}/fontsize/${staticDictionary[filledDots + 1]}`)
        .then(response => {
        })
        .catch(error => {
        });

    }
  };

  const handleChange = (event, theme) => {
    if (theme === null) {
      return;
    }
    axios.put(`${process.env.REACT_APP_API_URL}/api/Book/${bookData.id}/theme/${theme}`)
      .then(response => {
        setTheme(theme);
        onThemeChange(theme);
      })
      .catch(error => {
      });
  };

  const decreaseFontSize = () => {
    if (filledDots > 1) {
      setFilledDots(filledDots - 1);
      onFontSizeChange(staticDictionary[filledDots - 1]);
      axios.put(`${process.env.REACT_APP_API_URL}/api/Book/${bookData.id}/fontsize/${staticDictionary[filledDots - 1]}`)
        .then(response => {
        })
        .catch(error => {
        });
    }
  };

  
  const onCloseSettings = () => {
    onClose('')
  };

  const onLanguageTranslationChange = (event, code) => {
    setSelectedWordLanguage(code);
    if (code === null) {
      return;
    }
    axios.put(
      `${process.env.REACT_APP_API_URL}/api/user/translationLanguage`,
      { translationLanguage: code.name },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => {
        console.log('Translation language updated successfully:', response.data);
      })
      .catch(error => {
        console.error('Error updating translation language:', error);
      });
  };

  const onExplanationLanguageTranslationChange = async (event, code) => {
    setSelectedExplanationLanguage(code);
    if (code === null) {
      return;
    }
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/explanationLanguage`, { explanationLanguage: code.name }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      console.error('Error saving library language:', error);
    }

  };

  const buttonStyle = {
    backgroundColor: 'transparent', // White background
    color: '#00B2FF', // Blue text
    padding: '10px 20px', // Padding around the text
    border: '1px solid #00B2FF', // Blue border
    borderRadius: '5px', // Rounded corners
    cursor: 'pointer', // Pointer cursor on hover
    outline: 'none', // No focus outline
    fontSize: "20px",
    marginRight: "5px"
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    color: '#00B2FF',
  };

  return (
    <div ref={settingsRef} className="wordcontextx">
      <div className="popup">
        <div className="popup-inner" style={{
          display: showBookSettings ? 'block' : 'none',
          background: theme === 'white' ? 'white' : (theme === 'light' ? '#DBD6C5' : '#565656'),
          color: theme === 'dark' ? 'white' : 'black',
          position: 'relative' // Make the position relative for the close button
        }}>
          <CloseIcon style={closeButtonStyle} onClick={onCloseSettings} /> {/* X button */}
          <div className="color-mode">
            <div className="color-options">
              <button style={buttonStyle} onClick={increaseFontSize}>
                A <ExpandLessIcon fontSize='small' />
              </button>
              <button style={buttonStyle} onClick={decreaseFontSize}>
                a
                <ExpandMoreIcon fontSize='small' />
              </button>
            </div>
            <div style={{ display: 'flex', gap: '10px', marginBottom: '10px', marginTop: '10px', justifyContent: 'center' }}>
              {Array.from({ length: totalDots }, (_, index) => (
                <div
                  key={index}
                  style={{
                    width: '6px',
                    height: '6px',
                    borderRadius: '50%',
                    backgroundColor: index < filledDots ? '#00B2FF' : (theme === 'light' ? 'grey' : 'lightgrey')
                  }}
                />
              ))}
            </div>
          </div>
          <div className="night-mode" style={{ marginBottom: '0.5rem' }}>
            <label style={{ float: 'left', marginBottom: '0.3rem' }}>{t('wordsTranslationLanguage')}</label>
            <BookSettingsLanguageAutocomplete
              languages={gptLanguages}
              selectedLanguage={selectedWordLanguage}
              onLanguageChange={onLanguageTranslationChange}
              theme={theme}
            />
          </div>
          <div className="night-mode">
            <label style={{ float: 'left', marginBottom: '0.3rem' }}>{t('explanationContextLanguage')}</label>
            <BookSettingsLanguageAutocomplete
              languages={gptLanguages}
              selectedLanguage={selectedExplanationLanguage}
              onLanguageChange={onExplanationLanguageTranslationChange}
              theme={theme}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ float: 'left', marginTop: '0.5rem', marginBottom: '0.3rem' }}>{t('themes')}</label>
            <ToggleButtonGroup
              color="primary"
              value={theme}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              style={{ width: '100%' }}
            >
              <CustomToggleButton value="white" style={{ flex: 1, background: 'white', fontWeight: 'bold' }}>{t('white')}</CustomToggleButton>
              <CustomToggleButton value="light" style={{ flex: 1, background: '#F0EEDF', fontWeight: 'bold' }}>{t('light')}</CustomToggleButton>
              <CustomToggleButton value="dark" style={{ flex: 1, background: '#AAAAAA', fontWeight: 'bold' }}>{t('dark')}</CustomToggleButton>
            </ToggleButtonGroup>
          </div>

        </div>
      </div>
    </div>
  );
};

export default BookSettings;
