import React, { useState } from 'react';
import './Flashcard.css'
import Flashcard from './Flashcard';
import MainAppBar from '../homePage/AppBar';
import { useUser } from '../userContext/UserContext.js';
const FlashcardList = () => {
    const { userData, loading } = useUser();
    const sampleData = {
      mainWord: "being",
      revealWord: "to be",
      sentence: "Basgiath War College isn’t known for being kind to... well, anyone, even those of us whose mothers are in command.",
      source: "FourthWing"
    };
  
    return (
        <>
        <MainAppBar userData={userData}/>
      <div className="app-container">
        <Flashcard {...sampleData} />
      </div>
      </>
    );
  };
  
  export default FlashcardList;