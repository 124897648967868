import React, { useState, useEffect, useRef } from 'react';
import { useKeycloak } from "@react-keycloak/web";
import axios from '../components/AxiosConfig';

const useFetchSentencesBookHistory = (pageNumber, bookId) => {

  const [fetchError, setFetchError] = useState(null);
  const [sentencesHistoryData, setSentencesHistoryData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if(pageNumber.pageIndex == null)
        {
          return;
        }
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/HistoryBook/sentences/${bookId}/${pageNumber.pageIndex}/1000/${pageNumber.next}`);
        const data = response.data;
        setSentencesHistoryData(data);

      } catch (error) {
        setFetchError(error);
      }
    };

    fetchData();
  }, [pageNumber.pageIndex, pageNumber.next]);

  return { sentencesHistoryData };
};

export default useFetchSentencesBookHistory;