import Section from "./Section";
import Heading from "./Heading";
import { service1, service2, service3, check } from "../assets";
import wordContextGif from "../assets/word_context.gif";
import historyDesktop from "../assets/history_desktop.gif";
import historyMobile from "../assets/history_mobile.gif";
import wordContextMobileGif from "../assets/word_context_mobile.gif";
import textHighlightingMobile from "../assets/text_highlighting_mobile.gif";
import textHighlightingDesktop from "../assets/text_highlighting_desktop.gif";
import explainInContextDesktop from "../assets/explain_in_context_desktop.gif";
import explainInContextMobile from "../assets/explain_in_context_mobile.gif";
import test from "../assets/test.gif";
import { brainwaveServices, brainwaveServicesIcons } from "../constantsLanding";
import {
  PhotoChatMessage,
  Gradient,
  VideoBar,
  VideoChatMessage,
} from "./design/Services";
import panda from '../assets/services/book_smart.webp';
import Generating from "./Generating";
import { useState, useEffect } from "react";

const Services = () => {

  const [isMobile, setIsMobile] = useState(false);

  // Check for screen width changes
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768); // Mobile if screen width is less than 768px (Tailwind's md breakpoint)
    };

    checkScreenSize(); // Check on component mount
    window.addEventListener("resize", checkScreenSize); // Add listener for resize events

    return () => window.removeEventListener("resize", checkScreenSize); // Cleanup on unmount
  }, []);
  return (
    <Section>
      <div className="container">
        <Heading
          title="AI-Powered Ebook Reader Tailored for You"
          text="HappRead unlocks the potential of AI-powered applications"
        />

        <div className="relative">
          <div className="relative z-1 flex items-center h-[39rem] mb-5 p-8 border border-n-1/10 rounded-3xl overflow-hidden lg:p-20 xl:h-[46rem]">
            <div className="absolute top-0 left-0 w-full h-full pointer-events-none md:w-3/5 xl:w-auto">
              <img
                className="w-full h-full object-cover md:object-right"
                style={{opacity: isMobile ? 0.1 : 0.4}}
                width={800}
                alt="Smart ebook reader"
                height={730}
                src={panda}
              />
            </div>

            <div className="relative z-1 max-w-[17rem] ml-auto" style={{color: 'black'}}>
              <h4 className="h4 mb-4">Smart ebook</h4>
              <p className="body-2 mb-[3rem] text-n-3" style={{color: 'black'}}>
                HappRead unlocks the potential of AI-powered applications
              </p>
              <ul className="body-2">
                {brainwaveServices.map((item, index) => (
                  <li
                    key={index}
                    className="flex items-start py-4 border-t border-n-6"
                  >
                    <img width={24} height={24} src={check} />
                    <p className="ml-4">{item}</p>
                  </li>
                ))}
              </ul>
            </div>

            {/* <Generating className="absolute left-4 right-4 bottom-4 border-n-1/10 border lg:left-1/2 lg-right-auto lg:bottom-8 lg:-translate-x-1/2" /> */}
          </div>

          <div className="relative z-1 grid gap-5 lg:grid-cols-2" id="how-to-use">
            {/* <div className="relative min-h-[39rem] border border-n-1/10 rounded-3xl overflow-hidden">
              <div className="absolute inset-0">
                <img
                  src={service2}
                  className="h-full w-full object-cover"
                  width={630}
                  height={750}
                  alt="robot"
                />
              </div>

              <div className="absolute inset-0 flex flex-col justify-end p-8 bg-gradient-to-b from-n-8/0 to-n-8/90 lg:p-15">
                <h4 className="h4 mb-4">Photo editing</h4>
                <p className="body-2 mb-[3rem] text-n-3">
                  Automatically enhance your photos using our AI app&apos;s
                  photo editing feature. Try it now!
                </p>
              </div>

              <PhotoChatMessage />
            </div> */}

            <div className="p-4 bg-[#00B2FF] rounded-3xl overflow-hidden sm:min-h-[46rem] lg:min-h-[46rem]">
              <div className="py-12 px-4 xl:px-8">
                <h4 className="h4 mb-4">Text highlighting</h4>
                <p className="body-2 mb-[2rem] text-[#FFFFFF]">
                  Enjoy intuitive text highlighting, allowing users to seamlessly navigate and focus on key sections of any book. With integrated translation functionality, readers can instantly translate highlighted text into their preferred language, making it easier to enjoy and understand content across different languages
                </p>
              </div>

              <div className="relative bg-n-8 rounded-xl overflow-hidden -mx-4 sm:mx-0">
                <img
                  src={isMobile ? textHighlightingMobile : textHighlightingDesktop}
                  className="w-full h-auto object-cover"
                  alt="Scary robot"
                />
              </div>
            </div>

            
            <div className="p-4 bg-[#00B2FF] rounded-3xl overflow-hidden sm:min-h-[46rem] lg:min-h-[46rem]">
              <div className="py-12 px-4 xl:px-8">
                <h4 className="h4 mb-4">Word context</h4>
                <p className="body-2 mb-[2rem] text-[#FFFFFF]">
                The "word context" feature provides a pop-up definition and contextual explanation when a user clicks on a word while reading. It helps users understand the meaning of the word within the sentence, offering relevant translations or definitions based on context
                </p>
              </div>

             <div className="relative bg-n-8 rounded-xl overflow-hidden -mx-4 sm:mx-0">
                <img
                  src={isMobile ? wordContextMobileGif : wordContextGif}
                  className="w-full h-auto object-cover"
                  alt="Scary robot"
                />
              </div>
            </div>
            
            <div className="p-4 bg-[#00B2FF] rounded-3xl overflow-hidden sm:min-h-[46rem] lg:min-h-[46rem]">
              <div className="py-12 px-4 xl:px-8">
                <h4 className="h4 mb-4">Explain in context</h4>
                <p className="body-2 mb-[2rem] text-[#FFFFFF]">
                  "Explain in context" in an ebook reader allows users to select a word or phrase and instantly receive definitions or explanations tailored to its use within the surrounding text. This feature helps readers understand complex or unfamiliar terms based on the specific context of the book, enhancing comprehension without leaving the reading interface.
                </p>
              </div>

             <div className="relative bg-n-8 rounded-xl overflow-hidden -mx-4 sm:mx-0">
                <img
                  src={isMobile ? explainInContextMobile : explainInContextDesktop}
                  className="w-full h-auto object-cover"
                  alt="Scary robot"
                />
              </div>
            </div>

            <div className="p-4 bg-[#00B2FF] rounded-3xl overflow-hidden sm:min-h-[46rem] lg:min-h-[46rem]">
              <div className="py-12 px-4 xl:px-8">
                <h4 className="h4 mb-4">Translation History</h4>
                <p className="body-2 mb-[2rem] text-[#FFFFFF]">
                Keep track of all your translated words and explanations in one place. Each translation is saved for easy reference, allowing users to revisit and reinforce their understanding of key terms and concepts from any book. This history makes it simple to deepen language skills and enhance comprehension over time.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
              </div>

             <div className="relative bg-n-8 rounded-xl overflow-hidden -mx-4 sm:mx-0">
                <img
                  src={isMobile ? historyMobile : historyDesktop}
                  className="w-full h-auto object-cover"
                  alt="Scary robot"
                />
              </div>
            </div>
          </div>

          <Gradient />
        </div>
      </div>
    </Section>
  );
};

export default Services;
