import React, { useState, useEffect } from 'react';
import './BooksGrid.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import { styled } from '@mui/material/styles';
import axios from '../../components/AxiosConfig';
import defaultImage from '../../images/book-default-min.png';
import { Link } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';

const RecentBooksGrid = () => {
  const [books, setBooks] = useState(null);
  const [page, setPage] = useState({ skip: 0, limit: 12 });
  const [loading, setLoading] = useState(false);
  const [allElements, setAllElements] = useState(null);
  const [loadedImages, setLoadedImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Book/recentbook/${page.skip}/${page.limit}`);
        const data = response.data;
        setAllElements(data.totalCount);

        const newLoadedImages = [];
        data.books.forEach(book => {
          newLoadedImages[book.id] = false;  // Initialize all as not loaded
        });
        setLoadedImages(newLoadedImages);

        setBooks((prevItems) => {
          if (prevItems === null) {
            return [...data.books];
          } else {
            return [...prevItems, ...data.books];
          }
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + 100 >= document.documentElement.offsetHeight && !loading && page.skip + page.limit < allElements) {
        loadMoreBooks();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, page, allElements]);

  const loadMoreBooks = () => {
    setPage(prevPage => ({
      skip: prevPage.skip + 12,
      limit: 12
    }));
  };

  const handleImageLoaded = (bookId) => {
    setLoadedImages(prev => ({ ...prev, [bookId]: true }));
  };

  const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#00B2FF',
    },
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#b2dfdb',
    },
  }));

  return (
    <div style={{ background: '#F8FAFB', borderRadius: '30px', marginTop: '10px', marginBottom: '10px', minHeight: '300px' }}>
      <div className="books-grid">
        {books && books.map((book) => (
          <div key={book.id} style={{ display: loadedImages && (!Object.keys(loadedImages).some(x => x === book.id) || Object.values(loadedImages).every(value => value === true) ? 'block' : 'none') }}>
            <Link to={`/bookdetails/${book.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <div className="book-tile">
                <img src={book.imageUrl || defaultImage} alt={book.title} onLoad={() => handleImageLoaded(book.id)} />
                <Box width="100%" mr={1} sx={{ marginTop: '5px', marginBottom: '3px' }}>
                  {book && book.currentPosition !== 0 &&
                    <CustomLinearProgress
                      style={{
                        marginLeft: '15%',
                        marginRight: '10%',
                        visibility: book && book.currentPosition !== 0 ? 'visible' : 'hidden'
                      }} variant="determinate"
                      value={parseInt((book.currentPosition / book.totalWordCount) * 100)} />
                  }
                </Box>
                <span className="book-name">{book.title}</span>
                <span className="book-author">{book.author}</span>
              </div>
            </Link>
          </div>
        ))}
      </div>
      {(loading || Object.values(loadedImages).some(value => value === false)) && <Box className="books-spinner">
        <div style={{ marginTop: '10px' }}>
          <BarLoader size={25} color="#00B2FF" />
        </div>
      </Box>}
    </div>
  );
};

export default RecentBooksGrid;
