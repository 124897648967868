import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaCheckCircle } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import axios from '../AxiosConfig';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import './Page.css';

const ChangeTranslationLanguage = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [initialLanguage, setInitialLanguage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const languages = [
    ['Polski', 'English', 'Русский'],
    ['Español', 'Português', 'Türkçe'],
    ['العربية', 'Français', '中文'],
    ['Tiếng Việt', 'Italiano', 'हिन्दी'],
    ['ภาษาไทย', 'Українська', '한국어'],
    ['Română', 'Deutsch', 'עברית'],
  ];

  const languageCodes = {
    'Polski': 'pl',
    'English': 'en',
    'Русский': 'ru',
    'Español': 'es',
    'Português': 'pt',
    'Türkçe': 'tr',
    'العربية': 'ar',
    'Français': 'fr',
    '中文': 'zh',
    'Tiếng Việt': 'vi',
    'Italiano': 'it',
    'हिन्दी': 'hi',
    'ภาษาไทย': 'th',
    'Українська': 'uk',
    '한국어': 'ko',
    'Română': 'ro',
    'Deutsch': 'de',
    'עברית': 'he',
    'Bahasa Indonesia': 'id',
    '日本語': 'ja',
    'Magyar': 'hu',
    'ქართული': 'ka',
    'Čeština': 'cs',
    'Azərbaycanca': 'az',
    'Slovenčina': 'sk',
    'Lietuvių kalba': 'lt',
    'Հայերեն': 'hy',
    'فارسی': 'fa',
    'Қазақ тілі': 'kk',
    'Nederlands': 'nl',
    'Ελληνικά': 'el',
    'Svenska': 'sv',
    'Latviešu valoda': 'lv',
    'Suomi': 'fi',
    'Eesti keel': 'et',
    'Dansk': 'da',
    'Norsk': 'no',
    'Български': 'bg',
    'Bahasa Melayu': 'ms'
  };

  useEffect(() => {
    const fetchCurrentLocale = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user`);
        if (response.status === 200 && response.data.locale) {
          const locale = response.data.locale;
          const language = Object.keys(languageCodes).find(key => languageCodes[key] === locale);
          setSelectedLanguage(language);
          setInitialLanguage(language);
        }
      } catch (error) {
        console.error('Error fetching current locale:', error);
      }
      setIsLoading(false);  // Set loading state to false after data is fetched
    };

    fetchCurrentLocale();
  }, []);

  const saveLanguage = async () => {
    setIsSaving(true);
    const locale = languageCodes[selectedLanguage];
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/locale`, { locale }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        setInitialLanguage(selectedLanguage);
        console.log('Language saved successfully');
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000); // Hide the alert after 3 seconds
      } else {
        console.error('Failed to save language');
      }
    } catch (error) {
      console.error('Error saving language:', error);
    }
    setIsSaving(false);
  };

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/home');
  };

  return (
    <div className="page-container">
      <div className="header">
        <button className="back-button" onClick={handleBackClick}><FaArrowLeft /></button>
        <h1 style={{ color: '#333', fontSize: '1.5em', margin: '0', borderBottom: '1px solid #ddd'}}>
          Change translation language
        </h1>
      </div>
      {isLoading ? (
        <div className="spinner-container">
          <Spinner as="span" className="modal-confirm-button-spinner" animation="border" role="status" aria-hidden="true" />
        </div>
      ) : (
        <>
      {showAlert && (
        <div className="bottom-alert">
          <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
            Language has been updated successfully.
          </Alert>
        </div>
      )}
      <div className="languages">
        {languages.map((column, index) => (
          <div key={index} className="language-column">
            {column.map((language, idx) => (
              <div
                key={idx}
                className={`language ${selectedLanguage === language ? 'selected' : ''}`}
                onClick={() => setSelectedLanguage(language)}
              >
                <span className="language-text">{language}</span>
                <span className="checkmark-container">
                  {selectedLanguage === language && (
                    <FaCheckCircle className="checkmark" />
                  )}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <button
        className="submit-button"
        style={{
          backgroundColor: selectedLanguage === initialLanguage ? 'gray' : '#00B2FF',
          width: '50%'
        }}
        onClick={saveLanguage}
        disabled={selectedLanguage === initialLanguage || isSaving}
      >
        {isSaving ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Save changes'}
      </button>
      </>
      )}
    </div>
  );
};

export default ChangeTranslationLanguage;
