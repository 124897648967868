import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../userContext/UserContext.js';
const I18nLoader = () => {
  const { i18n } = useTranslation();
  // const location = useLocation(); // Get the current location
  const { userData, loading } = useUser();
  const languageShortcuts = {
    'Polish': 'pl',
    'English': 'en',
    'Russian': 'ru',
    'Spanish': 'es',
    'Portuguese': 'pt',
    'Turkish': 'tr'
  };

  useEffect(() => {
    // const fetchUserLanguage = async () => {
    //   //try {
    //     //const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user`);
    //     //if (response.status === 200 && response.data.interfaceLanguage) {
    //     //}
    //   // } catch (error) {
    //   //   console.error('Error fetching user language:', error);
    //   // }
    // };
    if(loading === false){
      if(!userData){
        i18n.changeLanguage("en");
      }else{
      i18n.changeLanguage(languageShortcuts[userData.interfaceLanguage]);
      }
    }

    // Only fetch language if not on the /home page
    // if (location.pathname !== '/') {
    //   fetchUserLanguage();
    // }
  }, [i18n, loading]); // Add location.pathname to dependencies

  return <></>;
};

export default I18nLoader;
