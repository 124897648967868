import React, { useEffect, useState, useRef } from "react";
import './TranslationTooltip.css';
import useSignalRConnectionTooltipTranslation from "../../hooks/useSignalRConnectionTooltipTranslation"
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightTopIconWhite from "../../icons/ArrowRightTopIconWhite";

const TranslationTooltip = ({ position, displayTooltip, onTooltipClose, idTranslation, bookId, onExplainContextClick }) => {

    const { tooltipTranslation, clearTooltipTranslation } = useSignalRConnectionTooltipTranslation(idTranslation, bookId);
    const tooltipRef = useRef(null);

    useEffect(() => {
        if (displayTooltip) {
            if(tooltipTranslation !== '') {
                onTooltipClose(tooltipTranslation);
            }
            clearTooltipTranslation();
        }
        tooltipRef.current.style.transform = 'translateY(-100%)'
    }, [displayTooltip]);

    useEffect(() => {
        if (tooltipRef.current && tooltipTranslation !== "") {
            const rect = tooltipRef.current.getBoundingClientRect();
            if(rect.top < 0){
                tooltipRef.current.style.transform = 'translateY(0)'
            }

            if(rect.right >= window.outerWidth){
                tooltipRef.current.style.left = `${(window.outerWidth / 3)}px`;
            }

            if(rect.right + 20 >= window.outerWidth && tooltipRef.current.innerText.split(' ').length > 4) {
                const toolTipPagePercent = ((rect.right - rect.left) / window.outerWidth) * 100;
                if(toolTipPagePercent < 30){
                    tooltipRef.current.style.left = `${(window.outerWidth / 3)}px`;
                }
            }
        }
    }, [tooltipTranslation]);

    const style = {
        top: tooltipTranslation.length === 0 ? position.y -15 : position.y,
        left: position.x,
        display: displayTooltip,
        transform: 'translateY(-100%)',
        borderRadius: tooltipTranslation.length === 0 ? '4px' : '7px'
    };

    const handleClose = (e) => {
        tooltipRef.current.style.display = 'none';
        onTooltipClose(tooltipTranslation);
        clearTooltipTranslation();
    };

    const handleOpenExplanationContext = (e) => {
        onExplainContextClick(true);
    };

    return (
        <div
            ref={tooltipRef}
            // onClick={handleClose}
            className={`tooltip-init ${tooltipTranslation.length === 0 ? 'rotate-animation' : ''}`}
            style={style}
        >
          <div
          style={{
            position: 'absolute',
            top: 3,
            left: 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '1rem',
            fontWeight: 'bold',
            visibility: tooltipTranslation.length === 0 ? 'hidden' : 'visible',
            width: '100%' // Adjust this based on your container width
          }}
        >
          <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleOpenExplanationContext}>
            Explain in context 
            <ArrowRightTopIconWhite style={{ marginLeft: '8px', verticalAlign: 'middle' }} />
          </span>
        </div>
                <hr style={{ border: '1px solid white !important'}}/>
            <div
                onClick={handleClose}
                style={{
                    display: tooltipTranslation.length === 0 ? 'none' : 'block',
                    position: 'absolute',
                    top: '-3px',
                    right: '3px',
                    cursor: 'pointer'
                }}
            >
                <CloseIcon style={{ width: '1.5rem', height: '1.5rem' }}/>
            </div>
            <div
                className="arrow-down"
                style={{ visibility: tooltipTranslation.length === 0 ? 'hidden' : 'visible' }}
            ></div>
            <div
                className="tooltip-content"
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <div>{tooltipTranslation}</div>
            </div>
        </div>
    );
};

export default TranslationTooltip;
