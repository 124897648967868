import { check } from "../assets";
import { uncheck } from "../assets";
import { pricing } from "../constantsLanding";
import Button from "./Button";
import { useNavigate } from 'react-router-dom';

const PricingList = () => {
  const navigate = useNavigate();
  const nav = () => {
    navigate('/home');
  }
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="flex gap-[1rem] max-lg:flex-wrap">
        {pricing.map((item) => (
          <div
            style={{ background: '#000038' }}
            key={item.id}
            className="w-[19rem] max-lg:w-full h-full px-6 bg-n-8 border border-n-6 rounded-[2rem] lg:w-auto even:py-14 odd:py-8 odd:my-4 [&>h4]:first:text-color-2 [&>h4]:even:text-color-1 [&>h4]:last:text-color-3"
          >
            <h4 className="h4 mb-4">{item.title}</h4>

            <p className="body-2 min-h-[4rem] mb-3 text-n-1/50" style={{ color: 'white' }}>
              {item.description}
            </p>

            <div className="flex items-center h-[5.5rem] mb-6">
              {item.price && (
                <>
                  <div className="h3" style={{ color: 'white' }}>$</div>
                  <div className="text-[5.5rem] leading-none font-bold" style={{ color: 'white' }}>
                    {item.price}
                  </div>
                </>
              )}
            </div>

            <Button
              className="w-full mb-6"
              onClick={nav}
              white={!!item.price}
            >
              {item.price ? "Get started" : "Contact us"}
            </Button>

            <ul style={{ color: 'white' }}>
              {item.features.map((feature, index) => (
                <li
                  key={index}
                  className="flex items-start pt-[1.25rem] pb-[1.25rem] border-t border-n-6"
                >
                  <img src={feature.isAvailable ? check : uncheck} width={24} height={24} alt="Check" />
                  <p className="body-2 ml-4">{feature.feature}</p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingList;

