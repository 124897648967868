import React, { useState, useRef, useEffect } from 'react';
import {  useParams } from 'react-router-dom';
import TranslationTooltip from '../translationTooltip/TranslationTooltip';
import axios from '../../components/AxiosConfig';
import WordContext from '../wordContext/WordContext';
import './EbookReader.css';
import useSignalRConnectionWordContext from '../../hooks/useSignalRConnectionWordContext';
import useSignalRConnectionWordContextSentence from '../../hooks/useSignalRConnectionWordContextSentence';
import useSignalRConnectionWordContextExplainInContext from '../../hooks/useSignalRConnectionWordContextExplainInContext';
import useFetchData from '../../hooks/useFetchData';
import useFetchSentencesBookHistory from '../../hooks/useFetchSentencesBookHistory';
import useFetchWordsBookHistory from '../../hooks/useFetchWordsBookHistory';
import useFetchWordContextTranslationsHistory from '../../hooks/useFetchWordContextTranslationsHistory';
import TextEbook from '../textEbook/TextEbook';
import WordExplanation from '../wordExplanation/WordExplanation';
import SidebarEbook from '../sideBarEbook/SidebarEbook';
import TranslationTooltipHover from '../translationTooltipHover/TranslationTooltipHover';
import WordsCountLimit from '../wordsCountLimit/WordsCountLimit';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import useFetchBookData from '../../hooks/useFetchBookData';
import EbookBar from './EbookBar';
import PrevPage from '../../icons/PrevPage';
import NextPage from '../../icons/NextPage';
import PricingBoxModal from '../subscription/PircingBoxModal';
import { useTranslation } from 'react-i18next';
import SentenceExplanation from '../wordExplanation/SentenceExplanation';
import PercentageSettings from '../percentageSettings/PercentageSettings';
import PercentagePopup from '../percentageSettings/PercentagePopup';

const EbookReader = () => {
  const { bookId } = useParams();
  const [wordsArray, setWordsArray] = useState([]);
  const { t } = useTranslation();
  const { bookData } = useFetchBookData(bookId);
  const [pageNumber, setPageNumber] = useState({pageIndex: null, next: true});
  const [selectedWordId, setSelectedWordId] = useState('');
  const { textArray, bookContent } = useFetchData(pageNumber, bookId);
  const { sentencesHistoryData } = useFetchSentencesBookHistory(pageNumber, bookId);
  const { wordsHistoryData } = useFetchWordsBookHistory(pageNumber, bookId);
  const { wordContextTranslationsHistoryData } = useFetchWordContextTranslationsHistory(pageNumber, bookId);
  const [userData, setUserData] = useState(null);
  const [currentTheme, setCurrentTheme] = useState();
  const [wordContext, setWordContext] = useState('');
  const [lastSelectionRange, setLastSelectionRange] = useState({first: 0, last: 0});
  const [wordContextCurrentSentence, setWordContextCurrentSentence] = useState([{word: '', isWordContext: false},{word: '', isWordContext: false},{word: '', isWordContext: false}]);
  const [wordContextTranslation, setWordContextTranslation] = useState('');
  const [displayTranslationTooltip, setDisplayTranslationTooltip] = useState('none');
  const [displayExplanationContent, setDisplayExplanationContent] = useState(false);
  const [displaySentenceExplanation, setDisplaySentenceExplanation] = useState(false);
  const [displayPageSummary, setPageSummary] = useState(false);
  const [textBookGenerated, setTextBookGenerated] = useState(true);
  const [displayWordContext, setDisplayWordContext] = useState('none');
  const [displayOnWordOverContext, setDisplayOnWordOverContext] = useState('none');
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [wordContextPosition, setWordContextPosition] = useState({ x: 0, y: 0, height: 0});
  //const { wordContextDescription, clearWordContextDescription } = useSignalRConnectionWordContext();
  const [wordIpa, setWordIpa] = useState('');
  const [translations, setTranslations] = useState({orginalText: '', targetText: ''});
  const [explanation, setExplanation] = useState({explanation: '', word: ''});
  const [selectedText, setSelectedText] = useState('');
  const [cachedWordTranslations, setCachedWordTranslations] = useState([]);
  const [cachedSentenceTranslations, setCachedSentenceTranslations] = useState([]);
  const [cachedWordContextTranslations, setCachedWordContextTranslations] = useState([]);


  const [firstSelectedWordId, setFirstSelectedWordId] = useState('');
  const [lastSelectedWordId, setLastSelectedWordId] = useState('');

  const [translationId, setTranslationId] = useState('');
  const [wordContextTranslationId, setNewWordContextTranslationId] = useState('');
  const [wordContextExplanationId, setWordContextExplanationId] = useState('');
  const [wordExplanationId, setWordExplanationId] = useState('');
  const { wordContextSentence, onSetWordContextSentence, clearWordContextSentence } = useSignalRConnectionWordContextSentence(wordContextTranslationId, bookId);
  const { wordContextExplainInContext, clearWordContextExplainInContext } = useSignalRConnectionWordContextExplainInContext(wordContextExplanationId, bookId);
  const { wordContextDescription, clearWordContextDescription } = useSignalRConnectionWordContext(wordExplanationId, bookId);


  const [displayTranslationTooltipHover, setDisplayTranslationTooltipHover] = useState('none');
  const [translationTooltipHoverData, setDataTranslationTooltipHoverData] = useState({position: { x: 0, y: 0 }, translation: ''});

  const [pageIndexes, setPageIndexes] = useState({firstPageIndex: 0, lastPageIndex: 0});

  const [showWordsLimit, setShowWordsLimit] = useState(false);
  const [showPercentageSettings, setShowPercentageSettings] = useState(false);
  const [showPercentagePopup, setShowPercentagePopup] = useState(false);
  const [isBookSettingsClose, setIsBookSettingsClose] = useState(false);

  const [isBookEnded, setIsBookEnded] = useState(false);

  const [fontFilledDots, setFontFilledDots] = useState();

  const [isTooltipGenerated, setIsTooltipGenerated] = useState(true);
  const [isExplanationGenerated, setIsExplanationGenerated] = useState(true);

  const [bookPercentage, setBookPercentage] = useState(null);

  const [selectedTouchWordIds, setSelectedTouchWordIds] = useState([]);
  const [firstTouchCoordinates, setFirstTouchCoordinates] = useState({x: 0, y: 0});
  const [lastTouchCoordinates, setLastTouchCoordinates] = useState({x: 0});
  const [wordRefs, setWordRefs] = useState([]);
  const [wordSpaceRefs, setWordSpaceRefs] = useState([]);
  const [showPremium, setShowPremium] = useState(false);
  

  //limits
  const [translationTooltipLimit, setTranslationTooltipLimit] = useState(false);
  const [translationToolTipExplanationLimit, setTranslationToolTipExplanationLimit] = useState(false);
  const [wordContextSingleWordLimit, setWordContextSingleWordLimit] = useState(false);
  const [wordContextSentenceLimit, setWordContextSentenceLimit] = useState(false);
  const [wordContextExplanationLimit, setWordContextExplanationLimit] = useState(false);

  const firstTouchWordId = useRef(null);
  const lastTouchWordId = useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Disable global scroll when component mounts
    const originalOverflow = document.body.style.overflowY;
    document.body.style.overflowY = 'hidden';

    // Restore original scroll behavior on unmount
    return () => {
      document.body.style.overflowY = originalOverflow;
    };
  }, []);
  const setViewportHeight = () => {
    const viewportHeight = window.innerHeight * 0.82;
    document.documentElement.style.setProperty('--dynamic-vh', `${viewportHeight}px`);
  };

  useEffect(() => {
    // Set initial height
    setViewportHeight();

    // Update height on window resize
    window.addEventListener('resize', setViewportHeight);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', setViewportHeight);
  }, []);
  
  useEffect(() => {
    if (bookData) {
      setPageNumber({pageIndex: bookData.currentPosition, next: true});
    }
  }, [bookData]);

  const handleRefsUpdate = (refs) => {
    setWordRefs(refs);
  };

  const handleWordSpaceRefsUpdate = (refs) => {
    setWordSpaceRefs(refs);
  };

  useEffect(() => {
    if(wordRefs.current && wordRefs.current.length > 0){
    const newWordsArray = [];
      wordRefs.current.forEach(ref => {
        newWordsArray.push({id: parseInt(ref.id), value: ref.innerHTML}); // Spread the words array and push each word into wordsArray
      });
      setWordsArray(newWordsArray);
    }
    
  }, [wordRefs.current]);

  useEffect(() => {
    if(sentencesHistoryData !== null){
      const historyArray = [];
      for (let i = 0; i < sentencesHistoryData.length; i++) {
        historyArray.push({
          firstWordId: sentencesHistoryData[i].startPosition, 
          lastWordId: sentencesHistoryData[i].endPosition, 
          translation:sentencesHistoryData[i].targetText});
      }
      
      setCachedSentenceTranslations([...cachedSentenceTranslations, ...historyArray]);
    }
  }, [sentencesHistoryData]);

  useEffect(() => {
    if(wordsHistoryData !== null){

      const historyArray = [];
      for (let i = 0; i < wordsHistoryData.length; i++) {
        historyArray.push({
          wordId: wordsHistoryData[i].startPosition, 
          word: wordsHistoryData[i].orginalText, 
          translation:wordsHistoryData[i].targetText,
          ipa:'test'});
      }

      setCachedWordTranslations([...cachedWordTranslations, ...historyArray]);
    }
  }, [wordsHistoryData]);

  useEffect(() => {
    if(wordContextTranslationsHistoryData !== null){

      const historyArray = [];
      for (let i = 0; i < wordContextTranslationsHistoryData.length; i++) {
        historyArray.push({
          wordId: wordContextTranslationsHistoryData[i].position, 
          translation: wordContextTranslationsHistoryData[i].translation
        });
      }

      setCachedWordContextTranslations([...cachedWordContextTranslations, ...historyArray]);
    }
  }, [wordContextTranslationsHistoryData]);


  const onMouseDownWord = (e) => {
    if(isTooltipGenerated === false){
     return;
    }
    const selectedWordId = e.target.id;
    firstTouchWordId.current = parseInt(e.target.id);
    setFirstSelectedWordId(selectedWordId);
    setDisplayTranslationTooltip('none');
    setDisplayExplanationContent(false);
    setDisplaySentenceExplanation(false);
    setPageSummary(false);
    const touchX = e.clientX;
    const touchY = e.clientY -25;
    setFirstTouchCoordinates({x: touchX, y: touchY});


  };
  
  const handleMouseMove = (e) => {

    if(firstTouchWordId.current == null){
      return;
    }
    const mouseX = e.clientX;
  const mouseY = e.clientY;

  document.elementsFromPoint(mouseX, mouseY).forEach(element => {
    if (element.tagName === 'SPAN' && element.id !== '' && element.id !== 'root' && element.lastChild !== undefined && !/^\s*$/.test(e.currentTarget.textContent)) {
      const mouseSelectedWordId = parseInt(element.id);
      
      // Select to left
      if (firstTouchWordId.current > mouseSelectedWordId) {
        console.log("select left: ", firstTouchWordId.current, mouseSelectedWordId);

        const maxId = Math.max(firstTouchWordId.current, ...selectedTouchWordIds);
        for (let id = firstTouchWordId.current; id <= maxId; id++) {
          const wordElement = wordRefs.current.find(ref => ref?.id === id.toString());
          const spaceElement = wordSpaceRefs.current.find(ref => ref?.id === id.toString());
          if (wordElement) {
            wordElement.style.backgroundColor = 'transparent';
            wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
          }
          if (spaceElement) {
            spaceElement.style.backgroundColor = 'transparent';
            wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
          }
        }
        
        if (lastTouchWordId.current < mouseSelectedWordId) {
          console.log("Removing already selected elements", lastTouchWordId.current, mouseSelectedWordId);
          for (let id = lastTouchWordId.current; id <= mouseSelectedWordId; id++) {
            const wordElement = wordRefs.current.find(ref => ref?.id === id.toString());
            const spaceElement = wordSpaceRefs.current.find(ref => ref?.id === id.toString());
            if (wordElement) {
              wordElement.style.backgroundColor = 'transparent';
              wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
            }
            if (spaceElement) {
              spaceElement.style.backgroundColor = 'transparent';
              wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
            }
          }
        }

        const isExist = selectedTouchWordIds.some(x => x === mouseSelectedWordId);
        if (!isExist) {
          setSelectedTouchWordIds(currentWordIds => [...currentWordIds, mouseSelectedWordId]);
        }
        lastTouchWordId.current = mouseSelectedWordId;

        const wordsToSelect = wordRefs.current.filter(x => parseInt(x.id) <= parseInt(firstTouchWordId.current) && parseInt(x.id) >= parseInt(element.id));
        const wordsSpaceToSelect = wordSpaceRefs.current.filter(x => parseInt(x?.id) <= parseInt(firstTouchWordId.current) && parseInt(x?.id) >= parseInt(element.id));
        wordsToSelect.forEach(element => {
          if (element.className !== "newLine") {
            element.style.backgroundColor = currentTheme === 'dark'? '#0F7DA1': '#ADD8E6';
            element.style.color = currentTheme === 'dark' ? 'white' : 'black';
          }
        });
        wordsSpaceToSelect.slice(0, -1).forEach(element => {
          element.style.backgroundColor = currentTheme === 'dark'? '#0F7DA1': '#ADD8E6';
          element.style.color = currentTheme === 'dark' ? 'white' : 'black';
        });

      } else { // Select to right
        for (let id = firstTouchWordId.current; id >= Math.min(firstTouchWordId.current, ...selectedTouchWordIds); id--) {
          const wordElement = wordRefs.current.find(ref => ref?.id === id.toString());
          const spaceElement = wordSpaceRefs.current.find(ref => ref?.id === id.toString());
          if (wordElement) {
            wordElement.style.backgroundColor = 'transparent';
            wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
          }
          if (spaceElement) {
            spaceElement.style.backgroundColor = 'transparent';
            wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
          }
        }

        if (lastTouchWordId.current > mouseSelectedWordId) {
          for (let id = mouseSelectedWordId; id <= lastTouchWordId.current; id++) {
            const wordElement = wordRefs.current.find(ref => ref?.id === id.toString());
            const spaceElement = wordSpaceRefs.current.find(ref => ref?.id === id.toString());
            if (wordElement) {
              wordElement.style.backgroundColor = 'transparent';
              wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
            }
            if (spaceElement) {
              spaceElement.style.backgroundColor = 'transparent';
              wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
            }
          }
        }

        const isExist = selectedTouchWordIds.some(x => x === mouseSelectedWordId);
        if (!isExist) {
          setSelectedTouchWordIds(currentWordIds => [...currentWordIds, mouseSelectedWordId]);
        }
        lastTouchWordId.current = mouseSelectedWordId;

        const wordsToSelect = wordRefs.current.filter(x => parseInt(x.id) >= parseInt(firstTouchWordId.current) && parseInt(x.id) <= parseInt(element.id));
        const wordsSpaceToSelect = wordSpaceRefs.current.filter(x => parseInt(x?.id) >= parseInt(firstTouchWordId.current) && parseInt(x?.id) <= parseInt(element.id));
        wordsToSelect.forEach(element => {
          if (element.className !== "newLine") {
            element.style.backgroundColor = currentTheme === 'dark'? '#0F7DA1': '#ADD8E6';
            element.style.color = currentTheme === 'dark' ? 'white' : 'black';
          }
        });
        wordsSpaceToSelect.slice(0, -1).forEach(element => {
          element.style.backgroundColor = currentTheme === 'dark'? '#0F7DA1': '#ADD8E6';
          element.style.color = currentTheme === 'dark' ? 'white' : 'black';
        });
      }
    }
  });
  }

  const removeWordsSelection = (firstId, lastId) => {
    setDisplayTranslationTooltip('none');
    
    for (let id = firstId; id <= lastId; id++) {
      const wordElement = wordRefs.current.find(ref => ref?.id === id.toString());
      const spaceElement = wordSpaceRefs.current.find(ref => ref?.id === id.toString());
          
      if (wordElement) {
          wordElement.style.backgroundColor = 'transparent';
          wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
        }
          
      if (spaceElement) {
          spaceElement.style.backgroundColor = 'transparent';
          wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
        }
    }
      firstTouchWordId.current = null;
      lastTouchWordId.current = null;
      setSelectedTouchWordIds([]);
  }
  
  const onMouseUpWord = (e) => {
    if(firstTouchWordId.current === null || lastTouchWordId.current === null) {
      firstTouchWordId.current = null;
      lastTouchWordId.current = null;
      setSelectedTouchWordIds([]);
      return;
    }
    setTranslationTooltipLimit(false);

    setDisplayTranslationTooltip('block');
    setDisplayWordContext('none');
    const firstId = Math.min(firstTouchWordId.current, lastTouchWordId.current);
    const lastId = Math.max(firstTouchWordId.current, lastTouchWordId.current);
    const wordsSelected = wordsArray.filter(x => x.id >= firstId && x.id <= lastId);
    
    const newTranslationId = `id-${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
      setTranslationId(newTranslationId);

      if (wordsSelected.length < 2){
        removeWordsSelection(firstId, lastId);
        return;
      }
      if (userData.isSubscriptionActive === false && wordsSelected.length > 6 ||
          (userData.isSubscriptionActive && wordsSelected.length > 20)) { 
          setShowWordsLimit(true);
          removeWordsSelection(firstId, lastId);
          return;
    }

    const aiRequest = {
      id: newTranslationId,
      message: wordsSelected.map(word => word.value.trim()).join(' '),
      bookId: bookId,
      startPosition: parseInt(firstId),
      endPosition: lastId,
      bookLanguage: bookData.language,
      translationLanguage: userData.translationLanguage
    };
    setSelectedText(aiRequest.message);

    setIsTooltipGenerated(false);

    axios.post(`${process.env.REACT_APP_API_URL}/api/OpenAI`, aiRequest)
    .then(response => {
      setIsTooltipGenerated(true);
    })
    .catch(error => {
      setIsTooltipGenerated(true);
      if (error.response && error.response.status === 403) {
        setTranslationTooltipLimit(true);
        setShowPremium(true);
        setDisplayTranslationTooltip('none');
        for (let id = firstId; id <= lastId; id++) {
          const wordElement = wordRefs.current.find(ref => ref?.id === id.toString());
          const spaceElement = wordSpaceRefs.current.find(ref => ref?.id === id.toString());
      
          if (wordElement) {
            wordElement.style.backgroundColor = 'transparent';
            wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
          }
      
          if (spaceElement) {
            spaceElement.style.backgroundColor = 'transparent';
            wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
          }
        }
      }
    });
      setTooltipPosition({
        x: firstTouchCoordinates.x,
        y: firstTouchCoordinates.y,
      });
      setCachedSentenceTranslations([...cachedSentenceTranslations, 
        { firstWordId: firstTouchWordId.current, 
          lastWordId: lastTouchWordId.current, 
          translation: ``}]);
      setLastSelectionRange({first: firstTouchWordId.current, last: lastTouchWordId.current});
      firstTouchWordId.current = null;
      lastTouchWordId.current = null;
      setSelectedTouchWordIds([]);

  };

  const navigateToPage = (pageIndex) => {
    setIsBookSettingsClose(true);
    setPageNumber({pageIndex: pageIndex, next: true});
    setDisplayTranslationTooltip('none');
    setDisplayWordContext('none');
    setDisplayExplanationContent(false);
    setDisplaySentenceExplanation(false);
    setPageSummary(false);
    setCachedSentenceTranslations([]);
    setCachedWordContextTranslations([]);
    setCachedWordTranslations([]);
  };

  const handleNextPage = () => {
    setIsBookSettingsClose(true);
    if(pageIndexes.lastPageIndex === bookContent.totalWordCount){
      setIsBookEnded(true);
      return;
    }
    setPageNumber({pageIndex: pageIndexes.lastPageIndex + 1, next: true});
    setDisplayTranslationTooltip('none');
    setDisplayWordContext('none');
    setDisplayExplanationContent(false);
    setDisplaySentenceExplanation(false);
    setPageSummary(false);
    setCachedSentenceTranslations([]);
    setCachedWordContextTranslations([]);
    setCachedWordTranslations([]);
    setShowPercentageSettings(false);
    setShowPercentagePopup(false);
  };

  const handlePrevPage = () => {
    setIsBookSettingsClose(true);
    if(pageIndexes.firstPageIndex === 1){
      return;
    }
    if (pageNumber.pageIndex > 1) {
      const minIndex = Math.max(0, pageIndexes.firstPageIndex - 1);
      setPageNumber({pageIndex: minIndex, next: false});
      setDisplayTranslationTooltip('none');
      setDisplayWordContext('none');
      setDisplayExplanationContent(false);
      setDisplaySentenceExplanation(false);
      setPageSummary(false);
      setCachedSentenceTranslations([]);
      setCachedWordContextTranslations([]);
      setCachedWordTranslations([]);
      setShowPercentageSettings(false);
      setShowPercentagePopup(false);
    }
  };

  const setCurrentSentenceInWordContext = (context) => {
    const wordIndex = wordsArray.findIndex(x => x.id === parseInt(context.wordId));

    const elementAroundSelectedWordArray = getElementsAroundIndexForWordContext(wordsArray, wordIndex);
    const words = elementAroundSelectedWordArray.map(obj => obj.value);
    const joinedWrods = words.join(' ').replace("\r\n","");
      return joinedWrods;
  };

  const setCurrentSentenceForWordPractice = (context) => {
    const wordIndex = wordsArray.findIndex(x => x.id === parseInt(context.wordId));

    const elementAroundSelectedWordArray = getElementsAroundIndexForPracticeWords(wordsArray, wordIndex);
    const words = elementAroundSelectedWordArray.map(obj => obj.value);
    const joinedWrods = words.join(' ').replace("\r\n","");
      return joinedWrods;
  };

  const wordOnClick = async (context, e) => {
    if(displayTranslationTooltip === "block"){
      return;
    }
    if(displayWordContext === "block"){
      setDisplayWordContext("none");
      return;
    }
    setIsBookSettingsClose(true);
    if(context.wordId == ""){
      return;
    }
    clearWordContextSentence();
    clearWordContextDescription();
    let isCached = false;
    const currentSentence = setCurrentSentenceInWordContext(context);
    const sentenceForWordPractice = setCurrentSentenceForWordPractice(context);
    const wordContext = cachedWordTranslations.filter(x => x.wordId == context.wordId);
    const wordContextTranslation = cachedWordContextTranslations.filter(x => x.wordId == context.wordId);
    if(wordContext.length != 0){
      context.ipa = wordContext[0].ipa;
      context.translation = wordContext[0].translation;
      isCached = true;
      if(wordContextTranslation.length === 0){
        setWordContextSentenceLimit(true);
      }
      onSetWordContextSentence(wordContextTranslation[0]?.translation);
    }
    try {
      e.target.style.borderBottom = "2px solid #00B2FF";
      setWordContextTranslation('');
      setDisplayTranslationTooltip('none');
      setDisplayOnWordOverContext('none');
      setWordIpa('');
      setDisplayExplanationContent(false);
      setDisplaySentenceExplanation(false);
      setPageSummary(false);
      setDisplayWordContext('block');

      setSelectedWordId(context.wordId);
      setWordContext(context.word);
      setWordContextPosition({
        x: context.position.x,
        y: context.position.y,
        height: context.height
      });

      if(isCached === false){
        const wordRequest = {
          text: context.word,
          bookId: bookId,
          positionId: parseInt(context.wordId),
          context: sentenceForWordPractice,
          bookLanguage: bookData.language,
          translationLanguage: userData.translationLanguage
        };
  
          axios.post(`${process.env.REACT_APP_API_URL}/api/OpenAI/word`, wordRequest)
          .then(response => {
            setCachedWordTranslations([...cachedWordTranslations, { wordId: context.wordId, word: context.word, translation: response.data, ipa: "" }]);
            setTranslations({ orginalText: context.word, targetText: response.data });
            setWordContextTranslation(response.data);
          })
          .catch(error => {
            if (error.response && error.response.status === 403) {
              setWordContextSingleWordLimit(true);
              e.target.style.borderBottom = "";
              setDisplayWordContext("none");
              setShowPremium(true);
            }
          });
        onWordContextSentenceSingalRRun(currentSentence, context.wordId, context.word);
      }

      setWordIpa(context.ipa);
      setWordContextTranslation(context.translation);
      
    } catch (error) {
      console.error('Error making API call:', error);
    }
  };

  const onWordContextSentenceSingalRRun = (currentSentence, wordId, word) => {
    const newWordContextTranslationId = `id-${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
    setNewWordContextTranslationId(newWordContextTranslationId);
    const aiRequest = {
      id: newWordContextTranslationId,
      sentence: currentSentence,
      word: word,
      bookId: bookId,
      position: wordId,
      bookLanguage: bookData.language,
      translationLanguage: userData.translationLanguage
    };

      axios.post(`${process.env.REACT_APP_API_URL}/api/OpenAI/wordContextSentence`, aiRequest)
    .then(response => {
      setCachedWordContextTranslations([...cachedWordContextTranslations, {wordId: parseInt(wordId), translation: response.data}]);
    })
    .catch(error => {
      if (error.response && error.response.status === 403) {
        setWordContextSentenceLimit(true);
      }
    });
  }

  const onExplanationWordClose = () => {
    setDisplayExplanationContent(false);
  }

  const onExplanationSentenceClose = () => {
    setDisplaySentenceExplanation(false);
  }
  const handleWordContextExplanation = (visible) => {
    if(visible === false){
      // setDisplayWordContext('none');
      setDisplaySentenceExplanation(false);
      // if(wordContextDescription !== ''){
      //   setExplanation({targetText: wordContextDescription.join(''), orginalWord: wordContext});
      // }
      return;
    }

    if(displaySentenceExplanation){
      return;
    }
    const firstId = Math.min(lastSelectionRange.first, lastSelectionRange.last);
    const lastId = Math.max(lastSelectionRange.first, lastSelectionRange.last);
    const wordsSelected = wordsArray.filter(x => x.id >= firstId && x.id <= lastId);
    const explanationText = getElementsAroundIndexForWordContextExplanation(wordsArray, firstId, lastId, wordsSelected);

    const newWordExplanationId = `id-${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
    setWordContextExplanationId(newWordExplanationId);

    const aiRequest = {
      id: newWordExplanationId,
      sentence: explanationText.map(obj => obj.value).join(' ').replace("\r\n",""),
      selectedWords: wordsSelected.map(obj => obj.value).join(' ').replace("\r\n",""),
      bookId: bookId,
      bookLanguage: bookData.language,
      translationLanguage: userData.explanationLanguage
    };

    setIsExplanationGenerated(false);
      axios.post(`${process.env.REACT_APP_API_URL}/api/OpenAI/wordContextExplanation`, aiRequest)
    .then(response => {
    })
    .catch(error => {
      if (error.response && error.response.status === 403) {
        setTranslationToolTipExplanationLimit(true);
      }
    });

    clearWordContextExplainInContext();
    setDisplaySentenceExplanation(visible);
  };

  const handleExplanationContentVisibility = (visible) => {

    if(visible === false){
      setDisplayWordContext('none');
      setDisplayExplanationContent(false);
      if(wordContextDescription !== ''){
        setExplanation({targetText: wordContextDescription.join(''), orginalWord: wordContext});
      }
      return;
    }

    if(displayExplanationContent){
      return;
    }
    const wordIndex = wordsArray.findIndex(x => x.id === parseInt(selectedWordId));
    const elementAroundSelectedWordArray = getElementsAroundIndex(wordsArray, wordIndex);
    const words = elementAroundSelectedWordArray.map(obj => obj.value);
    const joinedWrods = words.join(' ').replace("\r\n","");
    const newWordExplanationId = `id-${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
    setWordExplanationId(newWordExplanationId);

    const aiRequest = {
      id: newWordExplanationId,
      sentence: joinedWrods,
      selectedWord: wordContext,
      bookId: bookId,
      bookLanguage: bookData.language,
      translationLanguage: userData.explanationLanguage
    };
      axios.post(`${process.env.REACT_APP_API_URL}/api/OpenAI/wordExplanation`, aiRequest)
    .then(response => {
    })
    .catch(error => {
      if (error.response && error.response.status === 403) {
        setWordContextExplanationLimit(true);
      }
    });

    clearWordContextExplainInContext();
    setDisplayExplanationContent(visible);
  };

  const getElementsAroundIndex = (arr, index) => {
    const start = Math.max(0, index - 5);
    const elementsBefore = arr.slice(start, index);
  
    const end = Math.min(arr.length, index + 11);
    const elementsAfter = arr.slice(index + 1, end);
  
    return [...elementsBefore, arr[index], ...elementsAfter];
  }

  const getElementsAroundIndexForWordContextExplanation = (arr, startIndex, endIndex, wordsSelected) => {
    const start = Math.max(0, startIndex - 6);
    const elementsBefore = arr.filter(x => x.id >= start && x.id < startIndex);
  
    const end = Math.min(arr.at(-1).id, endIndex + 6);
    const elementsAfter = arr.filter(x=> x.id >= endIndex + 1 && x.id <= end);
  
    return [...elementsBefore, ...wordsSelected, ...elementsAfter];
  }

  const getElementsAroundIndexForWordContext = (arr, index) => {
    const start = Math.max(0, index - 4);
    const elementsBefore = arr.slice(start, index);
  
    const end = Math.min(arr.length, index + 4);
    const elementsAfter = arr.slice(index + 1, end);
  
    return [...elementsBefore, arr[index], ...elementsAfter];
  }

  const getElementsAroundIndexForPracticeWords = (arr, index) => {
    const start = Math.max(0, index - 7);
    const elementsBefore = arr.slice(start, index);
  
    const end = Math.min(arr.length, index + 7);
    const elementsAfter = arr.slice(index + 1, end);
  
    return [...elementsBefore, arr[index], ...elementsAfter];
  }

  const tooltipTranslationClose = (translation) => {
    setDisplaySentenceExplanation(false);
    setTranslations({orginalText: selectedText, targetText: translation.join('')});

    const sentenceData = cachedSentenceTranslations[cachedSentenceTranslations.length - 1];

    if(sentenceData != undefined && sentenceData.length != 0){
      const index = cachedSentenceTranslations.findIndex(x => x === sentenceData);

      const updatedCachedSentenceTranslations = [...cachedSentenceTranslations];
      const elementToModify = updatedCachedSentenceTranslations[index];

      elementToModify.translation = translation.join('')

      setCachedSentenceTranslations(updatedCachedSentenceTranslations);
    }
    
  }

  const findDivById = (id) => {
    for (let i = 0; i < wordRefs.current.length; i++) {
      const currentDiv = wordRefs.current[i];
      if (currentDiv && currentDiv.id === id.toString()) {
        return currentDiv;
      }
    }
    return null; // Return null if not found
  };

  const onWordOverAction = (context) => {
    if(context.word === undefined){
      return;
    }
    const sentenceData = cachedSentenceTranslations.filter(x => x.firstWordId <= parseInt(context.wordId) && x.lastWordId >= parseInt(context.wordId));
    
    if(sentenceData.length != 0 && sentenceData[0].translation != ''){
      const divElement = findDivById(sentenceData[0].firstWordId);
    const rect = divElement.getBoundingClientRect();
        
        const x = rect.left;
        const y = rect.top -50;
      setDisplayTranslationTooltipHover('block');
      setDataTranslationTooltipHoverData({position: {x: x, y: y}, translation: sentenceData[0].translation, rect: rect});
    }
  }
  
  const onWordOutAction = (context) => {
    setDisplayTranslationTooltipHover('none');
  }

  const handlePageIndexes = (indexes) => {
    setPageIndexes({firstPageIndex: indexes.firstPageIndex, lastPageIndex: indexes.lastPageIndex});
    const pagePercent = (indexes.firstPageIndex / bookContent.totalWordCount) *100;
      setBookPercentage(pagePercent.toFixed(1));
  };

  // const onCachedWords = (cachedWords) => {
  //   console.log("onCachedWords")
  //   if(cachedWords.isWord){
  //     setCachedWordTranslations([...cachedWordTranslations, { wordId: cachedWords.wordId, word: cachedWords.value, translation: cachedWords.translation, ipa: '' }]);

  //   }else if(cachedWords.isSentence){
  //     setCachedSentenceTranslations([...cachedSentenceTranslations, 
  //       { firstWordId: cachedWords.sentenceRange[0], 
  //         lastWordId: cachedWords.sentenceRange[1], 
  //         wordIndices: [], 
  //         translation: cachedWords.translation}]);
  //   }
  // };

  const onCloseWordsLimit = () => {
    setShowWordsLimit(false);
  };

  const onClosePercentageSettings = () => {
    setShowPercentageSettings(false);
  };

  const onEbookClick = (e) => {
    if(e.target.lastChild && e.target.lastChild.className && e.target.lastChild.localName != "path" && e.target.lastChild.localName != "svg" && e.target.lastChild.localName != "g" && e.target.lastChild.className.includes("sidebar")){
      setDisplayTranslationTooltip('none');
      setDisplayWordContext('none');
      setIsBookSettingsClose(true);
      setDisplayExplanationContent(false);
      setDisplaySentenceExplanation(false);
      setShowPercentageSettings(false);
      setShowPercentagePopup(false);
      setPageSummary(false);
      setShowWordsLimit(false);
      clearWordContextSentence();
      clearWordContextExplainInContext();
    }
  };
  const onBookSliderChanged = (event, newValue) => {
    setBookPercentage(newValue);
  };

  const onBookSliderMouseUp = () => {
    const calculatedIndex = parseInt(bookContent.totalWordCount * (bookPercentage / 100));
    setPageNumber({pageIndex: calculatedIndex, next: true});

    setDisplayTranslationTooltip('none');
    setDisplayWordContext('none');
    setDisplayExplanationContent(false);
    setDisplaySentenceExplanation(false);
    setPageSummary(false);
  };

  const onBookGenerated = (isGenerated) => {
    setTextBookGenerated(isGenerated);
  };
  const onFontSizeChange = (filledDots) => {
    setFontFilledDots(filledDots);
  };

    //touch functions

  const onTextTouchOver = (e) => {
    const touchX = e.touches[0].clientX;
    const touchY = e.touches[0].clientY;

  document.elementsFromPoint(touchX, touchY).forEach(element => {
    if (element.tagName === 'SPAN' && element.id != '' && element.id != 'root' && element.lastChild != undefined && !/^\s*$/.test(e.currentTarget.textContent)) {
      const touchSelectedWordId = parseInt(element.id);
      //select to left
      if(firstTouchWordId.current > touchSelectedWordId) {
        const maaax = Math.max(firstTouchWordId.current, ...selectedTouchWordIds);
          for (let id = firstTouchWordId.current; id <= maaax; id++) {
            const wordElement = wordRefs.current.find(ref => ref?.id === id.toString());
            const spaceElement = wordSpaceRefs.current.find(ref => ref?.id === id.toString());
            if (wordElement) {
              wordElement.style.backgroundColor = 'transparent';
              wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
            }
        
            if (spaceElement) {
              spaceElement.style.backgroundColor = 'transparent';
              wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
            }
          }
          
        if (lastTouchWordId.current < touchSelectedWordId) {
          if(lastTouchWordId.current === null){
            return;
          }
          for (let id = lastTouchWordId.current; id <= touchSelectedWordId; id++) {
            const wordElement = wordRefs.current.find(ref => ref?.id === id.toString());
            const spaceElement = wordSpaceRefs.current.find(ref => ref?.id === id.toString());
        
            if (wordElement) {
              wordElement.style.backgroundColor = 'transparent';
              wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
            }
        
            if (spaceElement) {
              spaceElement.style.backgroundColor = 'transparent';
              wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
            }
          }
        }
        
        const isExist = selectedTouchWordIds.some(x => x === touchSelectedWordId)
          if(isExist === false){
            setSelectedTouchWordIds(currentWordIds => [...currentWordIds, touchSelectedWordId]);
          }
          lastTouchWordId.current = touchSelectedWordId;
        const wordsToSelect = wordRefs.current.filter(x => parseInt(x.id) <= parseInt(firstTouchWordId.current) && parseInt(x.id) >= parseInt(element.id));
        const wordsSpaceToSelect = wordSpaceRefs.current.filter(x => parseInt(x?.id) <= parseInt(firstTouchWordId.current) && parseInt(x?.id) >= parseInt(element.id));
        wordsToSelect.forEach(element => {
          if(element.className != "newLine"){ //to not color newLine 
          element.style.backgroundColor = currentTheme === 'dark'? '#0F7DA1': '#ADD8E6';
          element.style.color = currentTheme === 'dark' ? 'white' : 'black';
          }
  
          });
          wordsSpaceToSelect.slice(0, -1).forEach(element => {
            element.style.backgroundColor = currentTheme === 'dark'? '#0F7DA1': '#ADD8E6';
            element.style.color = currentTheme === 'dark' ? 'white' : 'black';
        });

      }
      else { // select to right
        if(firstTouchWordId.current === null){
          return;
        }
        for (let id = firstTouchWordId.current; id >= Math.min(firstTouchWordId.current, ...selectedTouchWordIds); id--) {
          const wordElement = wordRefs.current.find(ref => ref?.id === id.toString());
          const spaceElement = wordSpaceRefs.current.find(ref => ref?.id === id.toString());
          if (wordElement) {
            wordElement.style.backgroundColor = 'transparent';
            wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
          }
      
          if (spaceElement) {
            spaceElement.style.backgroundColor = 'transparent';
            wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
          }
        }
        if (lastTouchWordId.current > touchSelectedWordId) {
          for (let id = touchSelectedWordId; id <= lastTouchWordId.current; id++) {
            const wordElement = wordRefs.current.find(ref => ref?.id === id.toString());
            const spaceElement = wordSpaceRefs.current.find(ref => ref?.id === id.toString());
        
            if (wordElement) {
              wordElement.style.backgroundColor = 'transparent';
              wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
            }
        
            if (spaceElement) {
              spaceElement.style.backgroundColor = 'transparent';
              wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
            }
          }
        }
  
        const isExist = selectedTouchWordIds.some(x => x === touchSelectedWordId)
          if(isExist === false){
            setSelectedTouchWordIds(currentWordIds => [...currentWordIds, touchSelectedWordId]);
          }
        lastTouchWordId.current = touchSelectedWordId;
        const wordsSpaceToSelect = wordSpaceRefs.current.filter(x => parseInt(x?.id) >= parseInt(firstTouchWordId.current) && parseInt(x?.id) <= parseInt(element.id));

        const wordsToSelect = wordRefs.current.filter(x => parseInt(x.id) >= parseInt(firstTouchWordId.current) && parseInt(x.id) <= parseInt(element.id));
        wordsToSelect.forEach(element => {
          if(element.className != "newLine"){ //to not color newLine 
          element.style.backgroundColor = currentTheme === 'dark'? '#0F7DA1': '#ADD8E6';
          element.style.color = currentTheme === 'dark' ? 'white' : 'black';
          }
  
        });
        wordsSpaceToSelect.slice(0, -1).forEach(element => {
          element.style.backgroundColor = currentTheme === 'dark'? '#0F7DA1': '#ADD8E6';
          element.style.color = currentTheme === 'dark' ? 'white' : 'black';
      });
      }
      
    }

  });
  }
  const handleThemeChange = (theme) => {
    setCurrentTheme(theme);
  }
  const onTextTouchEnd = (e) => {
    if(firstTouchWordId.current === null || lastTouchWordId.current === null) {
      firstTouchWordId.current = null;
      lastTouchWordId.current = null;
      setSelectedTouchWordIds([]);
      return;
    }
    setDisplayTranslationTooltip('block');
    setDisplayWordContext('none');
    const firstId = Math.min(firstTouchWordId.current, lastTouchWordId.current);
    const lastId = Math.max(firstTouchWordId.current, lastTouchWordId.current);
    const wordsSelected = wordsArray.filter(x => x.id >= firstId && x.id <= lastId);
    if(wordsSelected.length === 0){
      return;
    }

    if (wordsSelected.length < 2){
        removeWordsSelection(firstId, lastId);
        return;
      }
    if (userData.isSubscriptionActive === false && wordsSelected.length > 6 ||
          (userData.isSubscriptionActive && wordsSelected.length > 20)) { 
        setShowWordsLimit(true);
        removeWordsSelection(firstId, lastId);
        return;
    }

    const newTranslationId = `id-${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
    setTranslationId(newTranslationId);
    
    const aiRequest = {
      id: newTranslationId,
      message: wordsSelected.map(word => word.value.trim()).join(' '),
      bookId: bookId,
      startPosition: parseInt(firstId),
      endPosition: lastId,
      bookLanguage: bookData.language,
      translationLanguage: userData.translationLanguage
    };
    console.log("SELECTED TEXT: ", aiRequest.message);
    setSelectedText(aiRequest.message);

    setIsTooltipGenerated(false);
    axios.post(`${process.env.REACT_APP_API_URL}/api/OpenAI`, aiRequest)
    .then(response => {
      setIsTooltipGenerated(true);
    })
    .catch(error => {
      setIsTooltipGenerated(true);
      if (error.response && error.response.status === 403) {
        setTranslationTooltipLimit(true);
        setShowPremium(true);
        setDisplayTranslationTooltip('none');
        for (let id = firstId; id <= lastId; id++) {
          const wordElement = wordRefs.current.find(ref => ref?.id === id.toString());
          const spaceElement = wordSpaceRefs.current.find(ref => ref?.id === id.toString());
      
          if (wordElement) {
            wordElement.style.backgroundColor = 'transparent';
            wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
          }
      
          if (spaceElement) {
            spaceElement.style.backgroundColor = 'transparent';
            wordElement.style.color = currentTheme === 'dark' ? '#AAAAAA' : 'black';
          }
        }
      }
    });
      setTooltipPosition({
        x: firstTouchCoordinates.x,
        y: firstTouchCoordinates.y,
      });

      setLastSelectionRange({first: firstTouchWordId.current, last: lastTouchWordId.current});
      firstTouchWordId.current = null;
      lastTouchWordId.current = null;
      setSelectedTouchWordIds([]);
  }

  const onTextTouchStart = (e) => {
    setIsBookSettingsClose(true);
    if(e.target.id === ""){
      return;
    }
    firstTouchWordId.current = parseInt(e.target.id);
    setDisplayTranslationTooltip('none');
    setDisplayExplanationContent(false);
    setDisplaySentenceExplanation(false);
    setPageSummary(false);
    const touchX = e.touches[0].clientX;
    const touchY = e.touches[0].clientY -25;
    setFirstTouchCoordinates({x: touchX, y: touchY});
  }

  const onShowPremium = (show) => {
    setShowPremium(show);
  }

  const onPremiumClose = () => {
    setShowPremium(false);
  }

  const onBookSettingsClosed = () => {
    setIsBookSettingsClose(false);
  }

  const onUserDataLoaded = (data) => {
    setUserData(data);
    setCurrentTheme(data.theme);
  }

  const onFurthestPageLocation = (location) => {
    navigateToPage(location);
    setShowPercentageSettings(false);
  }

  const onStartPageLocation = (location) => {
    navigateToPage(location);
    setShowPercentageSettings(false);
  }

  const onSpecificPageLocation = () => {
    setShowPercentageSettings(false);
    setShowPercentagePopup(true);
  }
  
  const showPercentageSettingsPopup = (show) => {
    setShowPercentageSettings(show);
  }
  
  const onClosePercentagePopup = () => {
    setShowPercentagePopup(false);
  }

  const onSpecificLocation = (location) => {
    navigateToPage(location);
    setShowPercentagePopup(false);
  }

  return (
    <div style={{
      touchAction: 'none',
      msTouchAction: 'none',
      background: !currentTheme || currentTheme === 'white' ? 'white' : (currentTheme === 'light' ? '#F0EEDF' : '#222222')
  }}>
      <EbookBar onFontSizeFilledDots={onFontSizeChange} 
                bookData={bookData} 
                onThemeChange={handleThemeChange} 
                isBookSettingsClose={isBookSettingsClose} 
                onBookSettingsClosed={onBookSettingsClosed}
                onUserDataLoaded={onUserDataLoaded}/>
      <div className='ebook-text' style={{ height: 'var(--dynamic-vh)' }} onClick={onEbookClick}>
        {<div className='prev-page' onClick={handlePrevPage} style={{cursor: 'pointer', visibility: isMobile ? 'collapse' : 'visible'}}>
        {bookContent && isBookEnded === false && <PrevPage/>}
        </div>}
        {userData && <TextEbook textArray={textArray} 
                   bookContent={bookContent}
                   onWordSelection={wordOnClick} 
                   onWordUp={onMouseUpWord} 
                   onWordDown={onMouseDownWord} 
                   onWordOver={onWordOverAction}
                   onWordOut={onWordOutAction}
                   onTextMouseMove={handleMouseMove}
                   onWordRefsUpdate={handleRefsUpdate}
                   onWordSpaceRefsUpdate={handleWordSpaceRefsUpdate}
                   page={pageNumber}
                   pageIndexes={handlePageIndexes}
                   onBookGenerated={onBookGenerated}
                   fontFilledDots={fontFilledDots}
                   bookData={bookData}
                   onTextTouchStart={onTextTouchStart}
                   onTextTouchEnd={onTextTouchEnd}
                   onTextTouchOver={onTextTouchOver}
                   theme={currentTheme}
                   isBookEnded={isBookEnded}
                   />}
        
        <TranslationTooltip 
          onExplainContextClick={handleWordContextExplanation}
          position={tooltipPosition} 
          displayTooltip={displayTranslationTooltip} 
          onTooltipClose={tooltipTranslationClose}
          idTranslation={translationId}
          bookId={bookId}
          />

        <TranslationTooltipHover 
          data={translationTooltipHoverData} 
          displayTooltip={displayTranslationTooltipHover} 
          onTooltipClose={tooltipTranslationClose}/>

        {displayExplanationContent && <WordExplanation 
                                        wordContext={wordContext} 
                                        wordContextDescription={wordContextDescription}
                                        onExplanationWordClose={onExplanationWordClose}
                                        wordContextPosition={wordContextPosition}
                                        currentTheme={currentTheme}
                                        onShowPremium={onShowPremium}
                                        reachLimit={wordContextExplanationLimit}/>}

        {displaySentenceExplanation && <SentenceExplanation 
                                        wordContextDescription={wordContextExplainInContext}
                                        onExplanationWordClose={onExplanationSentenceClose}
                                        wordContextPosition={tooltipPosition}
                                        currentTheme={currentTheme}
                                        onShowPremium={onShowPremium}
                                        reachLimit={translationToolTipExplanationLimit}/>}

        <WordsCountLimit 
        showWordLimit={showWordsLimit}
        onCloseWordsLimit={onCloseWordsLimit}
        theme={currentTheme}
        onShowPremium={onShowPremium}
        userData={userData}/>

        <PercentageSettings 
        showPercentageSettings={showPercentageSettings}
        onClosePercentageSettings={onClosePercentageSettings}
        theme={currentTheme}
        onSpecificPageLocation={onSpecificPageLocation}
        onFurthestPageLocation={onFurthestPageLocation}
        onStartPageLocation={onStartPageLocation}
        pageIndexes={pageIndexes}
        total={bookContent.totalWordCount}
        furthestLocation={bookContent.furthestLocation}
        />

        <PercentagePopup 
        showPercentagePopup={showPercentagePopup}
        onClosePercentagePopup={onClosePercentagePopup}
        theme={currentTheme}
        onSpecificLocation={onSpecificLocation}
        pageIndexes={pageIndexes}
        total={bookContent.totalWordCount}/>

        <WordContext 
          onExplainContextClick={handleExplanationContentVisibility} 
          position={wordContextPosition} 
          displayWordContext={displayWordContext} 
          text={wordContext} 
          translation={wordContextTranslation}
          wordContextSentence={wordContextSentence}
          wordContextCurrentSentence={wordContextCurrentSentence}
          wordContextTranslationId={wordContextTranslationId}
          currentTheme={currentTheme}
          onShowPremium={onShowPremium}
          wordContextSentenceLimit={wordContextSentenceLimit}/>

        {<div className='next-page' onClick={handleNextPage} style={{cursor: 'pointer', visibility: isMobile ? 'collapse' : 'visible'}}>
          {bookContent && isBookEnded === false && bookContent.totalWordCount != pageIndexes.lastPageIndex && <NextPage />}
          {bookContent && bookContent.totalWordCount === pageIndexes.lastPageIndex && isBookEnded === false && <button style={{background: '#616161', color: 'white',borderRadius: '15px', padding: '5px'}}>{t('bookSummary')}</button>}
        </div>}
        {showPremium && <PricingBoxModal visibility={showPremium} onPremiumClose={onPremiumClose}/>}
        <SidebarEbook translations={translations} explanation={explanation} bookId={bookId} currentTheme={currentTheme}/>
        {isMobile && <div className="ebook-nav">
          <div className="arrow-left" onClick={handlePrevPage}>
            {bookContent && isBookEnded === false && <PrevPage/>}
          </div>
          {bookContent && bookContent.totalWordCount !== pageIndexes.lastPageIndex &&<span className="book-percentage" onClick={() => showPercentageSettingsPopup(!showPercentageSettings)} style={{visibility: bookPercentage ? `visible` : 'collapse', color: currentTheme === 'dark' ? '#AAAAAA' : 'black'}}>{bookPercentage}%</span>}
          <div className="arrow-right" onClick={handleNextPage}>
          {bookContent && isBookEnded === false && bookContent.totalWordCount != pageIndexes.lastPageIndex && <NextPage />}
          {bookContent && bookContent.totalWordCount === pageIndexes.lastPageIndex && isBookEnded === false &&
          <button style={{ maxWidth: '120px', height: 'auto', borderRadius: '15px', background: '#616161', color: 'white', padding: '5px' }}>
          {t('bookSummary')}
        </button>}
          </div>
        </div>}

        {isMobile && bookContent && bookContent.totalWordCount !== pageIndexes.lastPageIndex && <div className="ebook-footer" onClick={() => showPercentageSettingsPopup(!showPercentageSettings)}>
          <span style={{textAlign: 'center', flexGrow: '1', color: '#96a3ac', fontSize: '11px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{bookData?.title}</span>
        </div>}
        
      </div>
      
      {!isMobile && <div className="ebook-slider" style={{ background: !currentTheme || currentTheme === 'white' ? 'white' : (currentTheme === 'light' ? '#F0EEDF' : '#222222') }}>
      <div style={{marginLeft: '10%', marginRight: '10%', fontWeight: 400, display:'flex'}}>
        <span style={{textAlign: 'left', width: '50%'}}></span>
        <span style={{textAlign: 'right', width: '50%', visibility: bookPercentage ? `visible` : 'collapse', color: !currentTheme || currentTheme === 'dark' ? '#AAAAAA' : 'black'}}>{bookPercentage}%</span>
      </div>
      <Box sx={{ marginLeft: '10%', marginRight: '10%'}}>
          <Slider
            sx={{'.MuiSlider-thumb':{color: '#00B2FF'}, '.MuiSlider-track':{color: '#00B2FF'}}}
            size="small"
            value={bookPercentage}
            defaultValue={0}
            aria-label="Small"
            onChange={onBookSliderChanged}
            onMouseUp={onBookSliderMouseUp}
            />
        </Box>
      </div>}
      {isMobile && <div style={{ background: !currentTheme || currentTheme === 'white' ? 'white' : (currentTheme === 'light' ? '#F0EEDF' : '#222222'), height: '11svh'}}>
        </div>}
    </div>
  );
};

export default EbookReader;
