// UserContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../../components/AxiosConfig';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [retry, setRetry] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  const publicRoutes = ['/', '/termsofservice'];

  useEffect(() => {
    if (publicRoutes.includes(location.pathname)) {
      setLoading(false);
      return;
    }

    const fetchUserData = async () => {
        if(userData){
            return;
        }
      setLoading(true); // Set loading to true on each fetch attempt
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user`);
        if (response.status === 200) {
          if (!response.data.isInitalLanguagesConfigured) {
            navigate('/initialLanguages');
            return;
          }
          setUserData(response.data);
          setRetry(false); // No need to retry if fetch succeeded
        } else {
          setRetry(true); // Set retry to true if fetch failed
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setRetry(true); // Retry on error
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();

    // Retry only if `retry` is true and `userData` is still not set
    if (retry && !userData) {
      fetchUserData();
    }
  }, [location.pathname, retry]);

  return (
    <UserContext.Provider value={{ userData, setUserData, loading }}>
      {children}
    </UserContext.Provider>
  );
};
