import React, { useState, useEffect } from "react";
import "./SidebarEbook.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TranslationHistory from "../historyTable/TranslationHistory";
import ExplanationHistory from "../explanationHistory/ExplanationHistory";
import HistoryIcon from '../../icons/HistoryIcon';
import { useTranslation } from 'react-i18next';

const SidebarEbook = ({translations, explanation, bookId, currentTheme}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { t } = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={`sidebar${isMobile ? 'mobile' : ''} ${isOpen ? "open" : ""}`} style={{background: !currentTheme || currentTheme === 'white' ? 'white' : (currentTheme === 'light' ? '#F0EEDF' : '#565656')}}>
      <div className="sidebar-content" style={{background: !currentTheme || currentTheme === 'white' ? 'white' : (currentTheme === 'light' ? '#C7C3B2' : '#312F2F')}}>
        <header className="sidebarEbook__header">{t('history')}</header>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            paddingLeft: 1
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            centered
            aria-label="basic tabs example"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#00B2FF",
              },
            }}
          >
            <Tab
              sx={{
                fontFamily: "Georgia",
                fontSize: "16px",
                "&.Mui-selected": { color: "#00B2FF" },
                textTransform: "none",
                color: currentTheme === 'dark' ? "white": "black",
              }}
              label={t('translations')}
            />
            <Tab
              sx={{
                fontFamily: "Georgia",
                fontSize: "16px",
                "&.Mui-selected": { color: "#00B2FF" },
                textTransform: "none",
                color: currentTheme === 'dark' ? "white": "black",
              }}
              label={t('explanations')}
            />
          </Tabs>
        </Box>
        <div role="tabpanel" hidden={value !== 0} style={{height:'76vh'}}>
          <TranslationHistory translations={translations} bookId={bookId} currentTheme={currentTheme}/>
        </div>
        <div role="tabpanel" hidden={value !== 1} style={{height:'76vh'}}>
          <ExplanationHistory explanation={explanation} bookId={bookId} currentTheme={currentTheme}/>
        </div>
      </div>
      <button  className={`toggle-button${isMobile ? 'mobile' : ''}`} style={{background: !currentTheme || currentTheme === 'white' ? 'white' : (currentTheme === 'light' ? '#DBD6C5' : '#565656')}} onClick={toggleSidebar}>
        <div style={{marginRight: '10px', display: 'grid'}}>
          <HistoryIcon/>
        </div>
      </button>
    </div>
  );
};

export default SidebarEbook;
