import React, { useState, useEffect } from 'react';
import BookShelf from './BookShelf';
import './HomePage.css'
import UploadBookDialog from './UploadBookDialog';
import SucceedBookUploadDialog from './SucceedBookUploadDialog';
import MainAppBar from './AppBar';
import axios from '../../components/AxiosConfig';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useUser } from '../userContext/UserContext.js';

const HomePage = () => {
    const [uploudedBook, setUploudedBook] = useState(null);
    const [isUploadOpen, setIsUploadOpen] = useState(null);
    const [drawerLanguage, setDrawerLanguage] = useState();
    // const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { userData, loading } = useUser();

// useEffect(() => {

//   const fetchUserData = async () => {

//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user`);
//       if (response.status === 200) {
//         if (!response.data.isInitalLanguagesConfigured) {
//           navigate('/initialLanguages');
//           return;
//       } else {
//         setLoading(false); 
//       }
//         setUserData(response.data);
//       }
//     }catch (error) {
//       setLoading(false);
//       console.error('Error fetching user data:', error);
//     }
//   };
//   fetchUserData();
// }, []);


    const handleBookUplouded = (book) => {
      setUploudedBook(book)
    };

    const handleOnClose = () => {
      setUploudedBook(null);
      setIsUploadOpen(false);
    };

    const handleDialogClose = () => {
      setIsUploadOpen(false);
    };

    const handleOnUploadText = () => {
      setIsUploadOpen(true)
    };

    const onDrawerLanguageChange = (lang) => {
      setDrawerLanguage(lang);
    };

    if (loading) {
      return (
        <div className="spinner-container-language">
        <CircularProgress sx={{ color: '#00B2FF' }} />
      </div>
      );
  }

    return (
        <div>
          <MainAppBar onUploadText={handleOnUploadText} onDrawerLanguageChange={onDrawerLanguageChange} userData={userData}/>
        <div className='book-shelf'>
          <UploadBookDialog onBookUpladed={handleBookUplouded} isOpen={isUploadOpen} onClose={handleDialogClose}/>
          <SucceedBookUploadDialog uploadedBook={uploudedBook} onClose={handleOnClose} isOpen={uploudedBook !== null}/>
        </div>
          <BookShelf drawerLanguage={drawerLanguage} userData={userData}/>
      </div>
    );
  };
  
  export default HomePage;