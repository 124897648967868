import React, { useState, useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import axios from '../../components/AxiosConfig';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import './Page.css';
import { useTranslation } from 'react-i18next';
import { useUser } from '../userContext/UserContext.js';

const Support = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alertTime, setAlertTime] = useState(false);
  const [error, setError] = useState('');
  const { userData, loading } = useUser();

  useEffect(() => {
    if(userData){
      setEmail(userData.email)
    }
  }, [userData]);

  const handleBackClick = () => {
    navigate('/home');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSending(true);

    axios.post(`${process.env.REACT_APP_API_URL}/api/messageuser`, { 
      email,
      message,
    })
    .then(response => {
      setSending(false);
      setSuccess(true);
      setAlertTime(true);
      setMessage(''); // Clear the textarea
      setTimeout(() => setAlertTime(false), 3000); // Hide the alert after 3 seconds
    })
    .catch(error => {
      console.error('Error sending message:', error);
      setSending(false);
      setError('Error sending message');
      setTimeout(() => setError(''), 3000); // Hide the alert after 3 seconds
    });
  };

  return (
    <div className="page-container-textarea">
      <div className="header">
        <button className="back-button" onClick={handleBackClick}><FaArrowLeft /></button>
        <h1 style={{ color: '#333', fontSize: '1.5em', margin: '0', borderBottom: '1px solid #ddd' }}>
          {t('writeToSupport')}
        </h1>
      </div>
      {loading ? (
        <div className="spinner-center-container">
          <Spinner as="span" className="modal-confirm-button-spinner" animation="border" role="status" aria-hidden="true" />
        </div>
      ) : (
        <>
          {alertTime && (
            <div className="bottom-alert">
              <Alert variant="success" onClose={() => setAlertTime(false)} dismissible>
                {t('messageSent')}!
              </Alert>
            </div>
          )}
          {error && (
            <div className="bottom-alert">
              <Alert variant="danger" onClose={() => setError('')} dismissible>
                {error}
              </Alert>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <label>
              E-mail
              <input type="email" name="email" className="email-input" value={email} disabled />
            </label>
            <label>
              {t('message')}
              <textarea
                className="message-input"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </label>
            <div className='spinner-center-container'>
            <button
              type="submit"
              className="submit-button"
              disabled={sending || success || !message.trim()}
              style={{ backgroundColor: success ? 'gray' : '#00B2FF', width: '50%'}}
            >
              {sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : t('send')}
            </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default Support;
